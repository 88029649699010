var commonHeaders = {
    'deviceType': 'w',
    'appVersion': '1.0',
    'Access-Control-Allow-Origin': 'true',
    'timezone': 'Asia/Kolkata'
}

function authHeader() {
    const token = localStorage.getItem('auth');
    return token
}

export function formDataHeader() {
    return { ...commonHeaders, "Content-Type": "application/x-www-form-urlencoded", "Authorization": authHeader() }
}

export function jsonHeaders() {
    return { ...commonHeaders, "Content-Type": "application/json", "authorization": authHeader() }
}

export function multipartHeaders() {
    return { ...commonHeaders, "Content-Type": "multipart/form-data", "Authorization": authHeader() }
}
