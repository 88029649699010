import React, { useState, useEffect, useContext, } from 'react';
import { userServices } from '../../services';
import Loader from '../common/Loader';
import { Row, Col, Dropdown } from "react-bootstrap";
import { context } from '../../GlobalContext/GlobalContext';
import { BOOKING_PAYMENT_DURATION_FILTER } from '../../utils/constant';
import { Link, useHistory } from "react-router-dom";


const TransactionDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [transactionData, setTransactionData] = useState()
    const globalData = useContext(context);
    const history = useHistory();

    useEffect(() => {
        getTransactionCount()
    }, [globalData.visibilityStatusFilter])

    function getTransactionCount() {
        setLoading(true);
        let params = {
            status: globalData.visibilityStatusFilter
        }
        userServices.getTransactionsData(params).then((res) => {
            if (res.status === 200) {
                setTransactionData(res?.data?.data)
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            {loading && <Loader />}
            <>

                <div className="top3_area">
                    <div className="btn_title_row">
                        <Row className='align-items-center'>

                            <Col sm={1} className="filters-style">
                                Filter:
                                <Dropdown onSelect={(e) => {
                                    globalData.setVisibilityStatusFilter(e);
                                }}>
                                    <Dropdown.Toggle>
                                        {globalData.visibilityStatusFilter === "MONTH" ? "Month"
                                            :
                                            globalData.visibilityStatusFilter === "WEEK" ? "Week"
                                                :
                                                globalData.visibilityStatusFilter === "YEAR" ? "Year"
                                                    :
                                                    "All"
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            BOOKING_PAYMENT_DURATION_FILTER.map((value, index) => {
                                                return (
                                                    <Dropdown.Item eventKey={value.value} key={`${value.value}_${index}`} className={globalData.visibilityStatusFilter === value.value ? 'theme-background-color' : ''}>{value.title}</Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>


                        </Row>
                    </div>

                    <div className="row">
                        <aside onClick={() => history.push("/bookingPayment")} className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "#0cc2aa" }}>${transactionData?.totalTransaction}</h3>
                                <p className="text-uppercase">TOTAL TRANSACTIONS</p>
                            </div>
                        </aside>
                        <aside onClick={() => history.push("/payout")} className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "rgba(255,119,80,10)" }}>${transactionData?.totalPayout}</h3>
                                <p className="text-uppercase">TOTAL PAYOUTS</p>
                            </div>
                        </aside>
                        <aside className="col-lg-3">
                            <div className="white_three">
                                <h3 className='mb-2' style={{ color: "rgba(35, 84, 99, 10)" }}>${transactionData?.totalRefund}</h3>
                                <p className="text-uppercase">TOTAL REFUNDS</p>
                            </div>
                        </aside>
                        <aside className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "rgb(151 23 157)" }}>${transactionData?.totalEarning}</h3>
                                <p className="text-uppercase">TOTAL EARNINGS</p>
                            </div>
                        </aside>
                    </div>
                </div>
            </>
        </>
    )
}
export default TransactionDashboard;