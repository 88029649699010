import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { userServices } from "../../services";
import Loader from "./Loader";
import { EMAILREGEX } from '../../assets/constants/constants';
import { context } from "../../GlobalContext/GlobalContext";

const Login = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const globalData = useContext(context);
    let history = useHistory();

    useEffect(() => {
        if (globalData.token.length > 0) {
            history.push('/userDashboard')
        }
    }, [globalData.token])

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const handleEmailChange = (e) => {
        setEmailError('')
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPasswordError('')
        setPassword(e.target.value);
    }

    function LoginClick(e) {
        e.preventDefault()
        toast.dismiss();
        const user = { email: email, password: password, role: 'Admin' }
        if (email == '') {
            setEmailError('Email required')
            setTimeout(() => { setEmailError('') }, 5000)
        }
        else if (!EMAILREGEX.test(email)) {
            setEmailError('Invalid email')
            setTimeout(() => { setEmailError('') }, 5000)
        }
        else if (password == '') {
            setPasswordError('Password required')
            setTimeout(() => { setPasswordError('') }, 5000)
        }
        else {
            setIsLoading(true);
            setEmailError('')
            setPasswordError('')
            userServices.AdminLoginApi(user).then((res) => {
                if (res.status === 200) {
                    setIsLoading(false);
                    localStorage.setItem('auth', res.data.access_token)
                    localStorage.setItem('user_id', res.data.user._id)
                    localStorage.setItem('email', res.data.user.email)
                    localStorage.setItem('name', res.data.user.first_name.charAt(0).toUpperCase() + res.data.user.first_name.slice(1) + " " + res.data.user.last_name.charAt(0).toUpperCase() + res.data.user.last_name.slice(1))
                    if (res.data.user.user_image) {
                        localStorage.setItem('picture', res.data.user.user_image)
                    }
                    history.push('/userDashboard')
                }
            }).catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                    setPasswordError(err.response.data.message)
                    setTimeout(() => { setPasswordError('') }, 5000)
                } else {
                    toast.error('Something went wrong.')
                }
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            <section className="login_section">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3 login-logo-box">
                                <img src={require('../../assets/images/logo.png')} alt="Native Life" />
                            </div>
                        </Col>
                        <Col lg={5} md={6} className="m-auto login_col_box">
                            <div className="login_box">
                                <h2>LOGIN</h2>
                                <div className="mt-4 pt-3">
                                    <Form onSubmit={(e) => LoginClick(e)} autoComplete="off">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <div>
                                                <Form.Control type="email" name="email" placeholder="Enter your email" className="input100" value={email} onChange={handleEmailChange} />
                                                {emailError && <div style={{ color: "red", fontSize: '12px' }}>{emailError}</div>}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3 mt-2" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <div className="position-relative">
                                                <Form.Control type={passwordShown ? "text" : "password"} name="password" placeholder="Enter your password" className="input100" value={password} onChange={handlePasswordChange} />
                                                <span className="show_img">
                                                    <img src={require('../../assets/images/show.svg').default} alt="password" onClick={togglePasswordVisiblity} />
                                                </span>
                                            </div>
                                            {passwordError && <div style={{ color: "red", fontSize: '12px' }}>{passwordError}</div>}
                                        </Form.Group>
                                        <Button variant="unset" type="submit" className="login_btn my-3">
                                            Login
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Login;