import React from 'react';
import { useEffect } from 'react';

const NotFound = () => {
    useEffect(() => {
        console.log("page not found")
    }, [])
    return (
        <div className="not-found-page">
            <h3>
                Page Not Found..
            </h3>
        </div>
    )
}
export default NotFound;