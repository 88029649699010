import { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Loader from '../common/Loader';
import { userServices } from "../../services";
import { config } from "../../config/config";
import { Row, Col, Tooltip, OverlayTrigger, Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { EMAILREGEX, ERROR_CLEARS_IN } from '../../assets/constants/constants';
import { useContext } from 'react';
import { context } from '../../GlobalContext/GlobalContext';
import DefaultUser from "../../assets/images/img.png";


const AdminProfile = () => {
    const globalData = useContext(context)

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disableUpdatePassword, setDisableUpdatePassword] = useState(true);
    // form fields
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [image, setImage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('')
    const [imageUploaded, setImageUploaded] = useState({ "status": false, "url": "" })
    // form validation errors
    const [emailError, setEmailError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [oldPasswordsError, setOldPasswordsError] = useState('');
    const [passwordsError, setPasswordsError] = useState('');
    const [confirmPasswordsError, setConfirmPasswordsError] = useState('');
    const [newPasswordsError, setNewPasswordsError] = useState('');

    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const userId = globalData?.userId || localStorage.getItem('user_id')
    let history = useHistory();

    useEffect(() => {
        getAdminDetails(userId);
    }, [])
    useEffect(() => {
        if (!oldPassword || !newPassword || !confirmPassword) {
            setDisableUpdatePassword(true)
        } else {
            setDisableUpdatePassword(false)
        }
    }, [oldPassword, newPassword, confirmPassword])
    function getAdminDetails(userId) {
        setLoading(true);
        let params = {
            user_id: userId
        }
        userServices.GetAdminUserDetails(params).then((response) => {
            let adminData = response.data.data
            setFirstName(adminData.first_name)
            setLastName(adminData.last_name)
            setEmail(adminData.email)
            setImage(adminData.user_image)
            localStorage.setItem('picture', adminData.user_image)
            setLoading(false);
        }).catch(error => {
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
            setLoading(false);
        })
    }

    const handleSubmit = () => {
        if (!firstName || !lastName || !email || !EMAILREGEX.test(email)) {
            if (!firstName) {
                setFirstNameError('First name cannot be empty')
                setTimeout(() => { setFirstNameError('') }, ERROR_CLEARS_IN)
            }
            if (!lastName) {
                setLastNameError('Last name cannot be empty')
                setTimeout(() => { setLastNameError('') }, ERROR_CLEARS_IN)
            }
            if (!email) {
                setEmailError('Email cannot be empty')
                setTimeout(() => { setEmailError('') }, ERROR_CLEARS_IN)
            } else if (!EMAILREGEX.test(email)) {
                setEmailError('Invalid email')
                setTimeout(() => { setEmailError('') }, ERROR_CLEARS_IN)
            }
        } else {
            const formData = new FormData();
            let name = firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase() + lastName.slice(1)
            localStorage.setItem('name', name)
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("email", email);
            formData.append("image", image);
            formData.append("user_id", userId);
            setLoading(true);
            userServices.UpdateAdminDetails(formData).then((response) => {
                if (response.status === 200) {
                    if (response.data.data) {
                        if (response.data.data.user_image) {
                            globalData.setHeaderImage(response.data.data.user_image);
                            localStorage.setItem('picture', response.data.data.user_image);
                            globalData.setIsImageTrigger(true);
                        }
                    }
                    let full_name = response?.data?.data?.first_name + response?.data?.data?.last_name
                    globalData.setFullName(full_name)
                    swal("Success", "Profile updated successfully", "success")
                } else {
                    swal("Error", "Server Error", "error")
                }
                setLoading(false);
            }).catch(error => {
                if (error?.response?.status === 401) {
                    localStorage.clear()
                    history.push("/")
                } else {
                    if (error?.response?.status === 401) {
                        localStorage.clear()
                        history.push("/")
                    } else {
                        swal("Error", "Server error", "error")
                    }
                }
                setLoading(false);
            })
        }
    }
    const handlePasswordSubmit = () => {
        if (!newPassword || !confirmPassword || !oldPassword) {
            if (!oldPassword) {
                setOldPasswordsError("Old Password field cannot be empty")
                setTimeout(() => { setOldPasswordsError('') }, ERROR_CLEARS_IN);
            }
            if (!newPassword) {
                setNewPasswordsError("New Password field cannot be empty")
                setTimeout(() => { setNewPasswordsError('') }, ERROR_CLEARS_IN);
            }
            if (!confirmPassword) {
                setConfirmPasswordsError("Confirm Password field cannot be empty")
                setTimeout(() => { setConfirmPasswordsError('') }, ERROR_CLEARS_IN);
            }
            return;
        }
        else if (newPassword.length < 8) {
            setNewPasswordsError("Length of New Password cannot be less than 8")
            setTimeout(() => { setNewPasswordsError('') }, ERROR_CLEARS_IN);
            return;
        }
        else if (confirmPassword.length < 8) {
            setConfirmPasswordsError("Length of Confirm Password cannot be less than 8")
            setTimeout(() => { setConfirmPasswordsError('') }, ERROR_CLEARS_IN);
            return;
        }
        else if (newPassword !== confirmPassword) {
            setConfirmPasswordsError("New Password and Confirm password fields must be same.")
            setTimeout(() => { setConfirmPasswordsError('') }, ERROR_CLEARS_IN);
            return;
        }
        else if (newPassword === oldPassword) {
            setPasswordsError("Old Password and New password cannot be same.")
            setTimeout(() => { setPasswordsError('') }, ERROR_CLEARS_IN);
            return;
        }
        else {
            // update password api call
            let passwordParams = JSON.stringify({
                old_password: oldPassword,
                new_password: newPassword
            })
            setLoading(true)
            userServices.ChangePassword(passwordParams).then((response) => {
                setLoading(false)
                swal("Success", "Password updated successfully", "success").then(() => { localStorage.clear(); history.push('/') })
            }).catch(error => {
                setPasswordsError("Old Password is incorrect!")
                setTimeout(() => { setPasswordsError('') }, ERROR_CLEARS_IN)
                setLoading(false)
            })
        }
    }

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleChange = e => {
        setImageUploaded({ "status": true, "url": URL.createObjectURL(e.target.files[0]) })
        setImage(e.target.files[0])
        setDisabled(false)
    };

    const toggleOldPasswordVisiblity = () => {
        setOldPasswordShown(!oldPasswordShown);
    };
    const toggleNewPasswordVisiblity = () => {
        setNewPasswordShown(!newPasswordShown);
    };
    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    return (
        <>
            {loading && <Loader />}
            <>
                <div className='sale_graph transaction_box mb-3'>
                    <Row>
                        <Col>
                            <h2 className='mb-2'>Admin Details</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <div>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="btn-sus-1">Upload Picture</Tooltip>
                                        }>
                                        {imageUploaded.status
                                            ?
                                            <img
                                                className="profile_picture"
                                                src={imageUploaded.url}
                                                width={100}
                                                height={100}
                                                onClick={handleClick}
                                                style={{ cursor: 'pointer' }}
                                                alt="Uploaded Picture"
                                            />
                                            :
                                            <img
                                                className="profile_picture"
                                                src={image ? config.imageUrl + image : require('../../assets/images/img.png')}
                                                width={100}
                                                height={100}
                                                onClick={handleClick}
                                                style={{ cursor: 'pointer' }}
                                                onError={(event) => { event.target.src = DefaultUser }}
                                                alt="Profile Picture"
                                            />
                                        }
                                    </OverlayTrigger>
                                </div>
                                <input type="file" accept="image/*"
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        setDisabled(false)
                                        setFirstName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                    }
                                    }
                                    value={firstName} />
                                {firstNameError && <div style={{ color: "red", fontSize: '12px' }}>{firstNameError}</div>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        setDisabled(false)
                                        setLastName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                                    }
                                    }
                                    value={lastName} />
                                {lastNameError && <div style={{ color: "red", fontSize: '12px' }}>{lastNameError}</div>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email/Phone no.</Form.Label>
                                <Form.Control
                                    disabled={true}
                                    value={email} />
                                {emailError && <div style={{ color: "red", fontSize: '12px' }}>{emailError}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <a className="reply-btn" onClick={() => { handleSubmit() }} disabled={disabled}>Update</a>
                        </Col>
                    </Row>
                </div>
                <div className='sale_graph transaction_box mb-3'>
                    <Row>
                        <Col md={3}>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control
                                    type={oldPasswordShown ? "text" : "password"}
                                    onChange={(e) => {
                                        if (!e.target.value.includes(" ")) {
                                            setOldPassword(e.target.value)
                                            setOldPasswordsError('')
                                            setNewPasswordsError('')
                                            setConfirmPasswordsError('')
                                            setPasswordsError('')
                                        }
                                    }}
                                    value={oldPassword} />
                                <span className="show_img_icon">
                                    <img src={require('../../assets/images/show.svg').default} alt="" onClick={toggleOldPasswordVisiblity} />
                                </span>
                                {oldPasswordsError && <div style={{ color: "red", fontSize: '12px' }}>{oldPasswordsError}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type={newPasswordShown ? "text" : "password"}
                                    onChange={(e) => {
                                        if (!e.target.value.includes(" ")) {
                                            setNewPassword(e.target.value)
                                            setOldPasswordsError('')
                                            setNewPasswordsError('')
                                            setConfirmPasswordsError('')
                                            setPasswordsError('')
                                        }
                                    }}
                                    value={newPassword} />
                                <span className="show_img_icon">
                                    <img src={require('../../assets/images/show.svg').default} alt="" onClick={toggleNewPasswordVisiblity} />
                                </span>
                                {newPasswordsError && <div style={{ color: "red", fontSize: '12px' }}>{newPasswordsError}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type={confirmPasswordShown ? "text" : "password"}
                                    onChange={(e) => {
                                        if (!e.target.value.includes(" ")) {
                                            setConfirmPassword(e.target.value)
                                            setOldPasswordsError('')
                                            setNewPasswordsError('')
                                            setConfirmPasswordsError('')
                                            setPasswordsError('')
                                        }
                                    }}
                                    value={confirmPassword} />
                                <span className="show_img_icon">
                                    <img src={require('../../assets/images/show.svg').default} alt="" onClick={toggleConfirmPasswordVisiblity} />
                                </span>
                                {confirmPasswordsError && <div style={{ color: "red", fontSize: '12px' }}>{confirmPasswordsError}</div>}
                                {passwordsError && <div style={{ color: "red", fontSize: '12px' }}>{passwordsError}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <a className="reply-btn" onClick={() => { handlePasswordSubmit() }} disabled={disableUpdatePassword}>Update Password</a>
                        </Col>
                    </Row>
                </div>
            </>
        </>
    )
}

export default AdminProfile;