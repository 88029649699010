import Admin from './components/layout/Admin';
import Routing from './components/layout/Routing';
// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/assets/css/style.css'

function App() {
  return (
    <div className="App">
      <Admin>
        <Routing />
      </Admin>
    </div>
  );
}

export default App;
