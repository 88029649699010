import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';
import { format } from 'date-fns';
import { userServices } from "../../services";
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';
import { ALL_GAME_PLAN_FILTER } from '../../utils/constant';
import { Pagination } from '../../utils/pagination';

const GamePlans = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalpages: 0,
        totalRecord: 0
    });

    useEffect(() => {
        globalData.setVisibilityStatusFilter('');
        allGameplans();
    }, []);

    useEffect(() => {
        if (isPaginationTrigger) {
            allGameplans();
        }
    }, [isPaginationTrigger])

    // useEffect(()=>{
    //     allGameplans()
    // }, [searchKeyword, isPaginationTrigger, globalData.visibilityStatusFilter])

    function allGameplans() {
        let params = {
            pageNo: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
            status: globalData.visibilityStatusFilter
        };
        userServices.GetAllGameplans(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data);
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.totalCount
                    }
                });
            }
            setIsPaginationTrigger(false);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        handlePagination('page', parseInt(event.selected));
        // globalData.setPageSelected(parseInt(event.selected))
        globalData.setPageSelected(parseInt(event.selected))
        setLoading(true);
        setIsPaginationTrigger(true);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        triggerListing();
        // allGameplans();
    }

    const resetSearch = () => {
        setSearchKeyword("");
        // setIsSearchStart(false);
        triggerListing();
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    const triggerListing = () => {
        setLoading(true);
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
    }

    // common function to handle pagination
    const handlePagination = (type, value) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                [type]: value
            }
        });
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">Gameplans</h2>
                                            </Col>
                                            <Col sm={1} className="filters-style">
                                                Filter:
                                                <Dropdown onSelect={(e) => {
                                                    globalData.setVisibilityStatusFilter(e);
                                                    triggerListing();
                                                }}>
                                                    <Dropdown.Toggle>
                                                        {globalData.visibilityStatusFilter === "active" ? "Active"
                                                            :
                                                            globalData.visibilityStatusFilter === "completed" ? "Completed"
                                                                :
                                                                globalData.visibilityStatusFilter === "delete" ? "Deleted"
                                                                    :
                                                                    globalData.visibilityStatusFilter === "draft" ? "Draft"
                                                                        :
                                                                        "All"
                                                        }
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            ALL_GAME_PLAN_FILTER.map((value, index) => {
                                                                return (
                                                                    <Dropdown.Item eventKey={value.value} key={`${value.value}_${index}`} className={globalData.visibilityStatusFilter === value.value ? 'theme-background-color' : ''}>{value.title}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Name/Email/Phone No.' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Created On</th>
                                                <th>Last Activity On</th>
                                                <th>Location</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameplans.length
                                                ?
                                                gameplans.map((gameplan, key) => {
                                                    return (
                                                        <tr key={`${gameplan._id}_${key}`} id={key}>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.title}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan.title}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.description}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan.description}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>

                                                                {format(new Date(gameplan.createdAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.updatedAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td className="table-data first w-25">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan_location}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan.gameplan_location}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-view-1">View Gameplan Details</Tooltip>
                                                                    }
                                                                >
                                                                    <Link to={{
                                                                        pathname: `/gameplan/${gameplan._id}`,
                                                                        state: {
                                                                            type: 'gameplan_list'
                                                                        }
                                                                    }}><i className="fa fa-eye view-eye" aria-hidden="true"></i></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ?
                                    <Pagination totalPages={paginationObj.totalpages} handlePageChange={handlePageChange} currentPage={paginationObj.page} /> : null
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default GamePlans;