import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";

import { Container, Row, Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from 'sweetalert';
import { format } from 'date-fns';

import { Pagination } from '../../utils/pagination';
import { userServices } from "../../services";
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';

const SuspendedGameplan = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: globalData.pageSelected || 0,
        totalpages: 0,
        totalRecord: 0
    });

    useEffect(() => {
        globalData.setMainTab("suspendedGameplan")
        localStorage.setItem('active_tab', "suspendedGameplan")
        allGameplans();
    }, [])

    useEffect(() => {
        if (isPaginationTrigger) {
            allGameplans();
        }
    }, [isPaginationTrigger,])

    function allGameplans() {
        let params = {
            page: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
        }
        setLoading(true);
        userServices.GetSuspendedGameplan(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data)
                console.log(res.data.data)
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.totalCount
                    }
                });
                setIsPaginationTrigger(false);
            }
            setLoading(false);
        }).catch(error => {
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: parseInt(event.selected),
            }
        });
        globalData.setPageSelected(parseInt(event.selected))
        setIsPaginationTrigger(true);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        allGameplans();
    }

    const resetSearch = () => {
        setSearchKeyword("");
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: 0,
            }
        });
        // setIsSearchStart(false);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">Suspended Gameplan</h2>
                                            </Col>

                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Title/Create by' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>

                                                {/* <Form.Control placeholder='Search by Title/Create by' value={searchKeyword}
                                                    onChange={e => {
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                                {isSearchStart ?<i className="fa fa-close" aria-hidden="true" onClick={() => {
                                                        setSearchKeyword("")
                                                        setIsSearchStart(false)
                                                        setPaginationObj(prevPaginationObj => {
                                                            return {
                                                                ...prevPaginationObj,
                                                                page: 0,
                                                            }
                                                        });
                                                        setIsPaginationTrigger(true);
                                                    }} />:<i className="fa fa-search" aria-hidden="true" onClick={() => {
                                                        if (searchKeyword) {
                                                            setIsSearchStart(true)
                                                            allGameplans()
                                                        }
                                                    }} />
                                                } */}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>

                                                <th>Title</th>
                                                <th>Create by</th>
                                                <th>Created On</th>
                                                <th>Price per person</th>
                                                <th>Location</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {console.log("gameplans.length", gameplans[0])}
                                            {gameplans.length ?
                                                gameplans.map((gameplan, key) => {
                                                    return (
                                                        <tr key={`${gameplan._id}_${key}`} id={key}>

                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.title}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.title}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.description}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.user.first_name + " " + gameplan.user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.createdAt), 'MM/dd/yyyy')}
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">${gameplan.entry_price_per_person}</Tooltip>
                                                                    }>
                                                                    <span>${gameplan.entry_price_per_person}</span>
                                                                </OverlayTrigger>
                                                            </td>

                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan_location}</Tooltip>
                                                                    }>
                                                                    <span>${gameplan.gameplan_location}</span>
                                                                </OverlayTrigger>
                                                            </td>

                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-view-1">View Gameplan Details</Tooltip>
                                                                    }>
                                                                    <Link to={{
                                                                        pathname: `/gameplan/${gameplan._id}`,
                                                                        state: {
                                                                            type: 'suspendedGameplan'
                                                                        }
                                                                    }}><i className="fa fa-eye view-eye" aria-hidden="true" /></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} currentPage={paginationObj.page} handlePageChange={handlePageChange} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default SuspendedGameplan;