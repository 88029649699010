import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { config } from "../../config/config";
import Loader from '../common/Loader';
import { userServices } from "../../services";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Tooltip, OverlayTrigger, Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { context } from '../../GlobalContext/GlobalContext';
import { format } from 'date-fns';
import DefaultUser from '../../assets/images/img.png'

const PayoutDetails = () => {
    const globalData = useContext(context)
    const [gameplan, setGameplan] = useState();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [payoutId, setPayoutId] = useState('')
    const history = useHistory();
    useEffect(() => {
        if (id) {
            getGameplanDetals(id);
            setPayoutId(id)
            globalData.setUserTabOpen(false)
            globalData.setMainTab('payout')
        }

    }, [id])


    function getGameplanDetals(gameplanId) {
        let params = {
            booking_id: gameplanId
        }
        setLoading(true);
        userServices.GetBookingDetailById(params).then((res) => {
            console.log("hello", res.data.data)
            setGameplan(res.data.data)
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
        })
    }




    return (
        <>
            {loading && <Loader />}
            {gameplan ?
                <>
                    <>
                        {gameplan.map((gameplan) => {
                            return (
                                <div className='sale_graph transaction_box mb-3'>
                                    <div className='bact-div'>
                                        <Button onClick={() => {
                                            history.location.state ? history.push(`/${history.location.state.type}`) : history.goBack()
                                        }} className='back-bt' variant="unset"> <i className="fa fa-angle-left" aria-hidden="true"></i>  Back</Button>
                                    </div>
                                    <Row>
                                        <Col>
                                            <h2 className='mb-2'>Booking Details</h2>
                                        </Col>
                                        <Col md={12}>
                                            {gameplan?.gameplan?.gameplan_image ?
                                                <img
                                                    className="profile_picture"
                                                    src={config.imageUrl + gameplan?.gameplan?.gameplan_image}
                                                    width={100}
                                                    height={100}
                                                    alt="Profile Picture"
                                                    onError={(event) => { event.target.src = DefaultUser }}
                                                />
                                                :
                                                <img
                                                    className="profile_picture"
                                                    src={require('../../assets/images/img.png')}
                                                    width={100}
                                                    height={100}
                                                    alt="Profile Picture"
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row className='user-detail-main'>
                                        <Col md={12} className="mb-3">
                                            <button className="btn btn-primary"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/userdetails/${gameplan?.gameplan.user}`,
                                                        state: {
                                                            type: 'payoutDetails',
                                                            id: payoutId
                                                        }
                                                    })
                                                }}
                                            >
                                                Gameplan Creator
                                            </button>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Gameplan Title:<h6>{gameplan?.gameplan?.title}</h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Description:<h6>{gameplan?.gameplan?.description}</h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Gameplan Location:<h6>{gameplan?.gameplan?.gameplan_location}</h6></span>
                                        </Col>

                                        <Col md={4} className="mb-3">
                                            <span>Booking Date:<h6>
                                                {format(new Date(gameplan?.createdAt), 'MM/dd/yyyy')}
                                            </h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Event Date:<h6>
                                                {format(new Date(gameplan?.booking_date), 'MM/dd/yyyy')}

                                            </h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Number of attendees:<h6>{gameplan?.number_of_attendes}</h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Price per person:<h6>$ {gameplan?.gameplan_price}</h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Total Duration:<h6>{gameplan?.gameplan?.total_duration_for_location}</h6></span>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <span>Booking for self:<h6>{gameplan?.bookingForSelf ? 'True' : 'False'}</h6></span>
                                        </Col>
                                    </Row>
                                </div>

                            )
                        })}
                    </>
                </>
                : null}
        </>
    )
}

export default PayoutDetails;