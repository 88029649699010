import React from 'react'

function PrivacyPolicy() {
    return (
        <React.Fragment>
            <section className='terms-condition-section'>
                <div className='container'>
                    <h3>Privacy Policy and Notice of Collection</h3>
                    <div className='main-heading'>
                        <p className='my-3'>Last Updated: February 2023</p>
                        <p className='my-3'>N8TIVE NIGHTLIFE, LLC (hereinafter “NNL”) believes strongly that protecting our customers' privacy and security is a top priority. This Privacy Statement provides you with detailed information about how NNL and its subsidiaries, divisions, affiliates, brands and other companies that link to this Privacy Statement collect, use, share, and protect personal information, and describes your choices and rights available. This Privacy Statement also applies to personal information we receive from business partners and other third parties. We encourage you to read this Privacy Statement carefully and if you have any questions to contact us using the methods listed below.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>1. Who We Are</h4>
                        <p>NNL collects, uses, and is responsible for certain personal information about you.
                        </p>
                    </div>

                    <div className='blog-content'>
                        <h4>2.	The Personal Information We Collect and Use</h4>
                        <p>NNL collects certain data in order to ensure you have the best experience possible when utilizing our applications or websites. This data may be provided by you directly when you make purchases through the application; participate in board and forums; participate in recruit-a-friend offers; register warranties; enter contests; provide complaints or feedback; participate in customer satisfaction or other surveys; or engage technical support. We also may collect data from cookies, error reports, and usage data from software running on your device.</p>
                        <p>In addition, we obtain data from third parties, utilizing the same practices outlined within this policy, as well as any other restrictions or limitations from that data source. Typically, these include third party companies who we utilize to provide access to services related to the application, social media sites to which you have granted access, and service providers who help us determine location-based services and IP addresses.</p>
                        <p>You have choices about what data we collect. When we ask you to provide data, you have the option to decline. However, if you do not provide data necessary for a product or feature, you may not be able to use that product or feature. What data we collect varies based on your use of NNL’s product or features. Data collected may include the following:
                        </p>
                        • First name, last name;<br />
                        • Location and geo-location tracking data;<br />
                        • Names of persons placing orders;<br />
                        • Spending and transaction amounts,<br />
                        • Businesses visited;<br />
                        • Your age,<br />
                        • Your gender;<br />
                        • Your home country;<br />
                        • Your preferences, including:<br />
                        ◦ favorited businesses,<br />
                        ◦ music preferences,<br />
                        ◦ and drink preferences<br />
                        • Delivery address;<br />
                        • Credit card of bank information;<br />
                        • Email address;<br />
                        • Login credentials;<br />
                        • Payment data;<br />
                        • Device data, including hardware and software specifications;<br />
                        • Product use data, including how you utilize the application, items you purchase, and settings you utilize;<br />
                        • Device, connectivity, and configuration data, including what devices and networks you utilize to access our products and services, including operating systems, other software installed, and product keys. We may also collect IP addresses and other device identifiers, as well as regional and language settings.<br />
                        • Performance data, including error reports, utilized to help us troubleshoot and improve our games and websites, contents of any chats or emails regarding troubleshooting or game feedback, data regarding any errors (including device information and other programs and data utilizing your computer at the time of the error).<br />
                        • Content when posted to the game or files, including user pictures, avatars, or any other file you upload, which we need to collect in order for you and others to use as directed.<br />
                        • Messages and non-public communications, whether within the game or within the forums, messaging platforms, and websites of NNL. Such information will only be shared with the person to whom the communication was directed.<br />
                        <p>
                            Certain collections of information, including credit card and bank information and payment data, are collected solely for immediate transmittal to third-party payment processors, and NNL does not view, process, store, retain, or perform any other action with regard to said data. Your financial data and personal information related to any store transaction cannot be accessed by NNL, or by any third-party attempting to access NNL, as this data does not exist. In this regard, the only information collected by NNL is whether the transaction was successful, as transmitted by the third party, in order to allow us to provide you the product you purchased.
                        </p>
                    </div>

                    <div className='blog-content'>
                        <p className='my-3'>3.	How We Use Personal Data</p>
                        <p className='my-3'>NNL uses the data we collect to provide you with access to our applications and forums and to send communications. We combine data from multiple services to ensure the application (including all in-application purchases and experiences) and forums are provided to you in a seamless experience. We use data not only to provide the product and forum access directly, but to provide support, product activation, product improvement; security, and dispute resolution. This includes information aimed at reducing fraudulent activity, activity contrary to our code of conduct, spam, cyberattacks, phishing, viruses, or other security and privacy concerns. We also utilize this information to make informed business decisions about development of our games and services, in order to provide you with the best user experience not only with the current game, but into the future as well.</p>
                        <p className='my-3'>Your personal information will not be shared with third parties other than those acting as agents of NNL, and your personal information will not be utilized by other third parties, nor will it be sold at any time.
                        </p>
                    </div>


                    <div className='blog-content'>
                        <h4>4.	Sharing of Personal Data</h4>
                        <p>
                            We share your personal data with your consent, or as necessary to complete any transaction to complete or provide any product you have requested or authorized, including transactions from our online store, which are processed and stored externally. This includes sharing with agents and affiliates who provide aspects of the applications and websites. We also share with vendors working on our behalf; when required to do so by law or to respond to legal processes or actions; to protect our customers from spam, fraud, or loss of life; to maintain security of our products, and to protect the rights or property of NNL, including enforcement of terms of use, codes of conduct, violations of law, or intellectual property.
                        </p>
                        <p>We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so when (1) permitted or required by law; (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions; or (3) investigating fraud which has already taken place.
                        </p>
                        <p>Sale of Personal Information: We may sell or share any information we collect about you, including without limitation your name, email address location data, transaction items and amounts, your age, your gender, your home country, and your preferences, with our marketing partners, including e.g. [Insert name] for the purpose of providing you with information about their products and services or other products and services.</p>
                        <p>If you participate in an NNL message board or other forum, you should be aware that the information disclosed and shared will be broadly available to other persons, both inside of and/or outside NNL, who have access to that message board or forum. Our message boards and forums contain additional rules and conditions regarding participation, which may be viewed within our legal disclaimers. Each participant’s expressed opinion is his or her own and should not be considered as reflecting the opinion of NNL.</p>
                        <p>In addition, messaging within any NNL application may involve the utilization of text messaging through your cellular telephone carrier. Any data sent to, or received by, any such carrier shall also be governed by your cellular telephone carrier contract, as well as any associated agreements.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>5.	Access and Control of Your Personal Data</h4>
                        <p>You may have the right to view, edit, or delete your personal data online. If you choose to delete data integral to your operation of the site or the game, you may be unable to utilize those services or may be required to re-enter data in order to use those services. You may access your personal data by emailing us at privacy@N8tiveNightlife.com or making edits to your personal data. You may also change your communication preferences, including any email preferences, from that page. Options to unsubscribe from email messages will also be provided in any email sent to you of a non-personal nature.</p>
                    </div>
                    <div className='blog-content'>
                        <h4>6.	Cookie/Tracking Technology</h4>
                        <p>We collect personal and other information using digital tracking tools, such as cookies and web beacons, when you use our websites or mobile applications, interact with our emails or applications provided on connected devices, or otherwise engage with our digital offerings. We also partner with third parties that collect information this way. These third parties may place similar tracking tools on our websites, applications, and advertising to facilitate tracking, advertising, and measurement services.</p>
                        <p>The tools may collect information about your activities over time and on various digital services offered by us or others.</p>
                        <p>
                            The tools that we and our service providers may use include:
                        </p>
                        <p>
                            • Browser and flash cookies. Cookies are small text files that websites send to your computer or other connected device to identify your browser or to store information or settings. Your browser may provide the ability to be notified when you receive certain types of cookies and how to restrict or disable certain cookies. <br />
                            • Server logs, which record information about the device, browser, operating system, network, and other resources you use to access our digital services<br />
                            • Web beacons and pixels. These are small electronic images deployed on websites and emails that collect information about how users interact with content<br />
                            • Application software. Software in our mobile and other connected-device applications collects information about how you interact with the digital services<br />
                        </p>
                        <p>We and our service providers, use the information collected via these tools to:</p>
                        <p>• Identify new visitors and users <br />
                            • Learn how you came to access our digital services <br />
                            • Keep you logged into our digital services <br />
                            • Personalize your experience on our website, device applications, and third-party social networking websites, plug-ins and applications <br />
                            • Identify your location as determined by the IP address of your devices <br />
                            • Optimize and tailor our websites and device applications <br /></p>
                        <p>You may control cookies using your web browser, which has controls to block and delete cookies and other tracking from some third-party analytics service providers to opt out of data collection through web beacons and similar technologies. Your browser and other choices may impact your experiences with regard to cookies and tracking technologies.</p>
                        <p>Our Do Not Track Policy: Some browsers have a "Do Not Track" feature that lets you tell websites that you do not want to have your online activities tracked. These browser features are not uniform. So, we are not currently set up to respond to those signals.</p>

                    </div>

                    <div className='blog-content'>
                        <h4>7.	Commitment to Data Security</h4>
                        <p>Your personally identifiable information is kept secure. Only authorized employees, agents and contractors who have agreed to keep information secure and confidential have access to this information. All emails and newsletters from this site allow you to opt out of further mailings.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>8.	Processing and Storage of Personal Data</h4>
                        <p>NNL may store or process personal data within the United States or within the region or country of any of our software as a service, payment, or other affiliates, vendors, or service providers. Each of our affiliates, vendors, and service providers are bound by contract to the same data standards we employ. NNL does not store any credit card or bank information. Nor does NNL store or retain any transaction details from any online store or in-application transaction, which is solely processed and stored by third party companies. The only information stored by NNL is whether the transaction was successful, as transmitted by the third party, in order to allow us to provide you the product you purchased.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>9.	Retention of Personal Data</h4>
                        <p>We ensure data is processed in accordance with this Privacy Statement and to the requirements of applicable law. We will store and retain your personal information in accordance with applicable law and as long as necessary to carry out the purposes described in this Privacy Statement in accordance with our internal data retention procedures. Because these needs can vary for different data types in the context of different products, actual retention periods may vary significantly. We judge data retention principles on how long data is needed to provide access to the applications or websites, to improve performance of our products, or to maintain appropriate business records; whether the data is that which was created by and designed for deletion by the end user (i.e. – user uploads and forum posts); whether the data is of a sensitive nature; and whether NNL has other obligations to maintain the data, including contractual, legal, or other obligations.</p>
                    </div>

                    <div className='blog-content'>
                        <h4> 10.	Data Collection from Children</h4>
                        <p>You must be 21 years of age to use this App and the Company’s Services. We do not knowingly collect nor will we knowingly ask children under the age applicable to their region to provide any data.
                        </p>
                    </div>

                    <div className='blog-content'>
                        <h4> 11.	Privacy Contact Information</h4>
                        <p>You have the right to lodge a complaint with the proper supervisory authority. If you have any questions, concerns, or comments about our privacy policy you may contact us by emailing MBG@N8tiveNightlife.com. Any inquiry shall be responded to within 30 days.</p>
                    </div>
                    <div className='blog-content'>
                        <h4>12.	We reserve the right to make changes to this policy. Any changes to this policy will be posted.</h4>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default PrivacyPolicy;