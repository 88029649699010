import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from 'sweetalert';
import { format } from 'date-fns';

import { Pagination } from '../../utils/pagination';
import { userServices } from "../../services";
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';

const IncompleteProfiles = () => {
    const globalData = useContext(context)
    const history = useHistory();
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalpages: 0,
        totalRecord: 0
    });

    useEffect(() => {
        incompleteUsersList();
        globalData.setUserTypeTab("");
        globalData.setMainTab("all_users");
    }, []);

    useEffect(() => {
        if (isPaginationTrigger) {
            incompleteUsersList();
        }
    }, [isPaginationTrigger]);

    function incompleteUsersList() {
        let params = {
            pageNo: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
        }
        setLoading(true);
        userServices.IncompleteProfileUsers(params).then((res) => {
            if (res.status === 200) {
                setUsersList(res.data.data);
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(res.data.pageNo),
                        totalpages: Math.ceil(res.data.usersListCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.usersListCount
                    }
                });
            }
            setIsPaginationTrigger(false);
            setLoading(false);
        }).catch(error => {
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        console.log("event:::::", event)
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: parseInt(event.selected),
            }
        });
        setIsPaginationTrigger(true);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        incompleteUsersList();
    }

    const resetSearch = () => {
        setSearchKeyword("");
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                page: 0,
            }
        });
        // setIsSearchStart(false);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="sale_graph transaction_box mb-3">
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">User Management</h2>
                                            </Col>
                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Name/Email/Phone No.' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Name</th>
                                                <th>Email/Phone No.</th>
                                                <th>Created On</th>
                                                <th>Last Activity On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersList.length
                                                ?
                                                usersList.map((user, key) => {
                                                    return (
                                                        <tr key={`${user._id}_${key}`} id={key}>
                                                            <td>{paginationObj.page > 0 ? `${paginationObj.page}${key + 1}` : key + 1} </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{user.first_name + " " + user.last_name}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{user.first_name + " " + user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{user.email ? user.email : user.phone_number}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{user.email ? user.email : user.phone_number}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(user.createdAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td>
                                                                {format(new Date(user.updatedAt), 'MM/dd/yyyy')}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={5}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} currentPage={paginationObj.page} handlePageChange={handlePageChange} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default IncompleteProfiles;