import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { context } from "../../GlobalContext/GlobalContext";
import { config } from "../../config/config";
import DefaultUser from "../../assets/images/img.png"

const Header = () => {
    const globalData = useContext(context);
    const [userImg, setUserImg] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const activeTab = globalData.subTab;
    let history = useHistory();

    let backUrl = '/unapproved'
    if (activeTab === 'native_users') {
        backUrl = '/unapproved'
    } else if (activeTab === 'newbie_users') {
        backUrl = '/nb'
    } else if (activeTab === 'incomplete_profile_users') {
        backUrl = '/incomplete'
    }

    useEffect(() => {
        var tempImg = globalData?.headerImage || localStorage.getItem('picture');
        var email = globalData?.email || localStorage.getItem('email');
        setEmail(email ? email : '');
        let name = globalData?.fullName || localStorage.getItem('name');
        if (name) {
            setName(name.charAt(0).toUpperCase() + name.slice(1));
        } else {
            setName('');
        }
        if (tempImg != 'undefined' || tempImg != undefined) {
            setUserImg(tempImg)
        }
    }, []);

    useEffect(() => {
        if (globalData.isImageTrigger) {
            setUserImg(globalData.headerImage);
            globalData.setIsImageTrigger(false);
        }
    }, [globalData.isImageTrigger])

    function logoutUser() {
        localStorage.clear();
        globalData.setToken('');
        setTimeout(() => {
            history.push('/');
        }, 1000)
    }
    return (
        <>
            <header>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <div className="main-header">
                                <div className="main_logo">
                                    <img src={require('../../assets/images/header_logo_new.png')} alt="logo" />
                                </div>
                                <div>
                                    <ul className="m-0 p-0">
                                        <li>
                                            <div className="admin_name">
                                                <h4 className="mb-3">{name}</h4>
                                            </div>
                                            <Dropdown className="profile_dropdown">
                                                <Dropdown.Toggle variant="unset" className="border-0" id="dropdown-basic">
                                                    <span className="user_profile">
                                                        <img src={userImg ? config.imageUrl + userImg : require('../../assets/images/img.png')} alt="user"
                                                            onError={(event) => { event.target.src = DefaultUser }} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <p>Logged in</p>
                                                    <h6 className="mb-3">{email}</h6>
                                                    <Dropdown.Item onClick={() => { history.push(window.location.pathname === '/profile' ? backUrl : '/profile') }}>
                                                        {window.location.pathname === '/profile' ? 'Dashboard' : 'My Profile'}
                                                    </Dropdown.Item>
                                                    {/* PREVIOUS CODE DO NOT REMOVE */}
                                                    {/* {
                                                            window.location.pathname == '/profile' 
                                                            ?
                                                            <Dropdown.Item onClick={() => { history.push(backUrl) }}>
                                                            Dashboard
                                                            </Dropdown.Item>
                                                            :
                                                            <Dropdown.Item onClick={() => { history.push('/profile') }}>
                                                                My Profile
                                                            </Dropdown.Item>
                                                        } */}
                                                    <Dropdown.Item onClick={() => logoutUser()}>
                                                        Sign Out
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    )
}
export default Header;