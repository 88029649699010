import React, { useState, useEffect } from 'react';
import Loader from '../common/Loader';
import swal from 'sweetalert';
import { userServices } from "../../services";



function Commission() {
    const [percentage, setPercentage] = useState('');
    const [loading, setLoading] = useState(false);
    const [commissionId, setCommissionId] = useState('')

    useEffect(() => {
        getCommission();
        window.scrollTo(0, 0);
    }, [])


    function getCommission() {
        setLoading(true);
        userServices.getCommission().then((response) => {
            setLoading(false);

            if (response.data.data) {
                setCommissionId(response.data.data._id);
                setPercentage(response.data.data.commission);
            }
        }).catch((error) => {
            setLoading(false);

        })
    }


    function addCommission() {
        let params = {
            commission: percentage
        };
        userServices.addCommission(params).then((res) => {
            setLoading(false);
            swal("Success", "Commission added successfully", "success");
            getCommission();
        }).catch(error => {
            setLoading(false);
            swal("Error", "Server error Please refresh the page", "error")
        })
    }

    function updateCommission() {
        let params = {
            commission: percentage,
        };
        userServices.updateCommission(params, commissionId).then((res) => {
            setLoading(false)
            swal("Success", "Commission updated successfully", "success");
            getCommission();
        }).catch(error => {
            setLoading(false);
            swal("Error", "Server error Please refresh the page", "error")
        })
    }

    function handleInput(event) {
        let { value } = event.target;
        if (/[0-9]/.test(value)) {
            if (value < 100) {
                setPercentage(value);
            } else {
                swal("Error", "Percentage should be less than 100%", "error")
            }
        } else {
            setPercentage('');
        }

    }

    function handleFormSubmit(event) {
        event.preventDefault();
        setLoading(true);
        if (percentage.trim()) {
            if (parseInt(percentage) > 0) {
                if (commissionId) {
                    updateCommission();
                } else {
                    addCommission();
                }
            } else {
                swal("Error", "commission cannot be zero", "error")
                setLoading(false);
            }
        } else {
            swal("Error", "Please add commission (in %)", "error")
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            {
                loading ? <Loader /> : false
            }
            <div className='container'>
                <div className='row sale_graph mb-3'>
                    <div className='col-md-4'>
                        <form onSubmit={(event) => { handleFormSubmit(event) }}>
                            <div className="form-group mb-3">
                                <label for="percentage">Enter Commission (in %)</label>
                                <input type="text" className="form-control mt-1" id="percentage" value={percentage} onChange={(event) => handleInput(event)} required />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Commission;