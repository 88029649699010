export const ALL_BOOKING_FILTER = [{ title: 'All', value: '' }, { title: 'Upcoming', value: 'Upcoming' }, { title: 'Complete', value: 'Complete' }, { title: 'Cancelled', value: 'CANCELLED' }, { title: "Onhold", value: "onhold" }];

export const ALL_GAME_PLAN_FILTER = [{ title: 'All', value: '' }, { title: 'Active', value: 'active' }, { title: 'Completed', value: 'completed' }, { title: 'Deleted', value: 'delete' }, { title: 'Draft', value: 'draft' }];

export const BOOKING_PAYMENT_DURATION_FILTER = [{ title: 'All', value: '' }, { title: 'Month', value: 'MONTH' }, { title: 'Week', value: 'WEEK' }, { title: 'Year', value: 'YEAR' }];

export const BOOKING_PAYMENT_FILTER = [{ title: 'All', value: '' }, { title: 'Refund', value: 'REFUND' }, { title: 'Success', value: 'SUCCESS' }, { title: 'Cancelled', value: 'CANCELLED' }];
//{ title: 'Pending', value: 'PENDING' } =>>>>>> BOOKING_PAYMENT_FILTER

export const BOOKING_PAYOUT_FILTER = [{ title: 'All', value: '' }, { title: 'Pending', value: 'PENDING' }, { title: 'Success', value: 'SUCCESS' }];

export const USER_BOOKING_FILTER = [{ title: 'All', value: '' }, { title: 'Pending', value: 'PENDING' }, { title: 'Cancelled', value: 'CANCELLED' }, { title: 'Accepted', value: 'ACCEPTED' }, { title: 'Rejected', value: 'REJECTED' }];

export const ALL_TRANSACTION_FILTER = [{ title: 'All', value: '' }, { title: 'Month', value: 'MONTH' }, { title: 'Week', value: 'WEEK' }, { title: 'Year', value: 'YEAR' }];

export const PAGINATION_OBJECT = { page: 0, totalpages: 0 };