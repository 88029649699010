export const DATE_OPTIONS = { 
    month: 'short', 
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}
export const EMAILREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const USER_TYPE = {
    NATIVE: 'NA', 
    NEWBIE: 'NW'
}
export const ERROR_CLEARS_IN = 5000;
export const LIMIT_PER_PAGE = 10;