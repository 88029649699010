import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import swal from "sweetalert";
import { format } from "date-fns";
import { Pagination } from "../../utils/pagination";
import { userServices } from "../../services";
import Loader from "../common/Loader";
import { USER_TYPE } from "../../assets/constants/constants";
import { context } from "../../GlobalContext/GlobalContext";
import { LIMIT_PER_PAGE } from "../../assets/constants/constants";
import "../../assets/css/style.css";
import Swal from "sweetalert2";

const NativeApprovedUsers = () => {
  const globalData = useContext(context);
  const history = useHistory();
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearchStart, setIsSearchStart] = useState(false);
  const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
  const [paginationObj, setPaginationObj] = useState({
    page: globalData.pageSelected || 0,
    totalPages: 0,
    totalRecord: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    allUsersList();
    globalData.setSubTab("native_users");
    globalData.setMainTab("all_users");
    globalData.setUserTypeTab("approved");
  }, []);

  useEffect(() => {
    if (isPaginationTrigger) {
      allUsersList();
    }
  }, [isPaginationTrigger]);

  function allUsersList() {
    let params = {
      type: USER_TYPE.NATIVE,
      pageNo: paginationObj.page,
      size: LIMIT_PER_PAGE,
      search: searchKeyword,
    };
    setLoading(true);
    userServices
      .GetApprovedUsersList(params)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setUsersList(res.data.data);
          handlePagination("page", parseInt(res.data.pageNo));
          handlePagination(
            "totalPages",
            Math.ceil(res.data.usersListCount / LIMIT_PER_PAGE)
          );
          handlePagination("totalRecord", res.data.usersListCount);
          setCurrentPage(parseInt(res.data.pageNo));
          setIsPaginationTrigger(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          history.push("/");
        } else {
          // setLoading(false);
          swal("Error", "Server error", "error");
        }
      });
  }
  const deleteBtn = (id) => {
    console.log("id", id);
    userServices.deleteAccount(id).then((res) => {
      console.log("deleted User", res);
      Swal.fire({
        title: "Deleted Account!",
        text: "Account will be deleted",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          history.push("/");
        }
      });
    });
  };
  const deleteAction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBtn(id);
      }
    });
  };
  // Common function to handle Pagination
  const handlePagination = (key, value) => {
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        [key]: value,
      };
    });
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setIsSearchStart(true);
    allUsersList();
  };

  const clearSearch = () => {
    setSearchKeyword("");
    // setIsSearchStart(false)
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        page: 0,
      };
    });
    setIsPaginationTrigger(true);
    setTimeout(() => {
      setIsSearchStart(false);
    }, 1000);
  };

  return (
    <>
      {loading && <Loader />}
      <section className="botmanagement_section">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="sale_graph transaction_box mb-3">
                <Row className="align-items-center">
                  <Col sm={6}>
                    <h2 className="mb-2">Native user</h2>
                  </Col>

                  <Col sm={4} className="search-box">
                    <form
                      onSubmit={(event) => {
                        handleSearchSubmit(event);
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search by Name/Email/Phone No."
                        className="form-control"
                        value={searchKeyword}
                        onChange={(e) => {
                          setSearchKeyword(e.target.value);
                        }}
                        required
                      />
                      {isSearchStart ? (
                        <button
                          type="button"
                          className="btn border-0"
                          onClick={() => clearSearch()}
                        >
                          <i className="fa fa-close" aria-hidden="true" />{" "}
                        </button>
                      ) : (
                        <button type="submit" className="btn border-0">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      )}
                    </form>
                    {/* <Form.Control placeholder='Search by Name/Email/Phone No.' value={searchKeyword}
                                            onChange={e => {
                                                setSearchKeyword(e.target.value)
                                            }}
                                        />
                                        {isSearchStart ?
                                            <i className="fa fa-close" aria-hidden="true" onClick={() => {
                                                setSearchKeyword("")
                                                setIsSearchStart(false)
                                                setPaginationObj(prevPaginationObj => {
                                                    return {
                                                        ...prevPaginationObj,
                                                        page: 0,
                                                    }
                                                });
                                                setIsPaginationTrigger(true);
                                            }} />
                                            :
                                            <i className="fa fa-search" aria-hidden="true" onClick={() => {
                                                if (searchKeyword) {
                                                    setIsSearchStart(true)
                                                    allUsersList()
                                                }
                                            }} />
                                        } */}
                  </Col>
                </Row>

                {/* <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={9}>
                                                <h2 className="mb-2">Verified Users</h2>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Control placeholder='Search by Email/Phone No.' value={searchKeyword}
                                                    onChange={e => {
                                                        handlePagination('page',0);
                                                        setIsPaginationTrigger(true);
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div> */}

                <div className="table_box text-center">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email/Phone No.</th>
                        <th>Created On</th>
                        <th>Last Activity On</th>
                        <th>Gender</th>
                        <th>Neighbourhood Area</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersList.length ? (
                        usersList.map((user, key) => {
                          return (
                            <tr id={key} key={`${user._id}_${key}`}>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.first_name + " " + user.last_name}
                                    </Tooltip>
                                  }
                                >
                                  <span>{`${user.first_name} ${user.last_name}`}</span>
                                </OverlayTrigger>
                              </td>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.email
                                        ? user.email
                                        : user.phone_number}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {user.email
                                      ? user.email
                                      : user.phone_number}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                {format(new Date(user.createdAt), "MM/dd/yyyy")}
                              </td>
                              <td>
                                {format(new Date(user.updatedAt), "MM/dd/yyyy")}
                              </td>
                              <td>{user.gender}</td>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.user_neighbourhood_area}
                                    </Tooltip>
                                  }
                                >
                                  <span>{user.user_neighbourhood_area}</span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      View User Details
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/userdetails/${user._id}`}>
                                    <i
                                      className="fa fa-eye view-eye"
                                      aria-hidden="true"
                                    />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      Delete Account
                                    </Tooltip>
                                  }
                                >
                                  <Link>
                                    <i
                                      onClick={() => deleteAction(user?._id)}
                                      className="fa fa-trash delete-icon"
                                      aria-hidden="true"
                                    />
                                  </Link>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={12}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {paginationObj.totalPages > 1 ? (
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={paginationObj.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(event) => {
                      handlePagination("page", parseInt(event.selected));
                      globalData.setPageSelected(parseInt(event.selected));
                      setIsPaginationTrigger(true);
                    }}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={currentPage}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default NativeApprovedUsers;
