import { Switch, Route } from "react-router-dom";
import Login from '../common/Login';
import Dashboard from '../Pages/Dashboard';
import UserDetails from '../Pages/UserDetails';
import AdminProfile from '../Pages/AdminProfile';
import IncompleteProfiles from '../Pages/IncompleteProfiles';
import NativeApprovedUsers from '../Pages/NativeApprovedUsers';
import NativeUnapprovedUsers from '../Pages/NativeUnapprovedUsers';
import GamePlans from '../Pages/GamePlans';
import GameplanDetails from '../Pages/GameplanDetails';
import NotFound from "../Pages/NotFound";
import Booking from "../Pages/Booking";
import BookingDetails from "../Pages/BookingDetails";
import SuspendedGameplan from "../Pages/SuspendedGameplan";
import AllTransactions from "../Pages/AllTransactions";
import TransactionDetails from "../Pages/TransactionDetails";
import BookingPayment from "../Pages/BookingPayment";
import Payout from "../Pages/Payout";
import PayoutDetails from "../Pages/PayoutDetails";
import BookingPaymentDetails from "../Pages/BookingPaymentDetails";
import ContactForm from "../Pages/ContactForm";
import ContactUs from "../Pages/ContactUs";
import TermsAndCondition from '../Pages/TermsAndCondition';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import Commission from '../Pages/Commission';
import HoldPaymentList from "../Pages/HoldPaymentList";
import UserDashboard from "../Pages/UserDashboard";
import TransactionDashboard from "../Pages/TransactionDashboard";

const Routing = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/approved" component={NativeApprovedUsers} />
                <Route exact path="/unapproved" component={NativeUnapprovedUsers} />
                <Route exact path="/nb" component={Dashboard} />
                <Route exact path="/gameplans" component={GamePlans} />
                <Route exact path="/incomplete" component={IncompleteProfiles} />
                <Route exact path="/profile" component={AdminProfile} />
                <Route exact path="/userdetails/:id" component={UserDetails} />
                <Route exact path="/gameplan/:id" component={GameplanDetails} />
                <Route exact path="/booking" component={Booking} />
                <Route exact path="/booking/:id" component={BookingDetails} />
                <Route exact path="/suspendedGameplan" component={SuspendedGameplan} />
                <Route exact path="/allTransactions" component={AllTransactions} />
                <Route exact path="/transactionDetails/:id" component={TransactionDetails} />
                <Route exact path="/bookingPayment" component={BookingPayment} />
                <Route exact path="/payout" component={Payout} />
                <Route exact path="/payoutDetails/:id" component={PayoutDetails} />
                <Route exact path="/bookingPaymentDetails/:id" component={BookingPaymentDetails} />
                <Route exact path="/contactForm/:id" component={ContactForm} />
                <Route exact path="/contactUs" component={ContactUs} />
                <Route exact path="/terms" component={TermsAndCondition} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/commission" component={Commission} />
                <Route exact path="/holdPayment" component={HoldPaymentList} />
                <Route exact path="/userDashboard" component={UserDashboard} />
                <Route exact path="/transactionDashboard" component={TransactionDashboard} />
                <Route component={<NotFound />} />
            </Switch>
        </>
    )
}
export default Routing;