import Header from '../common/Header';
import Footer from '../common/Footer'
import Sidebar from '../common/Sidebar';
import React from 'react';
import { useLocation } from 'react-router';


const Admin = (props) => {
   const location = useLocation();

   return (
      <>
        {location.pathname !='/' && location.pathname !='/terms' && location.pathname !='/privacy'&& <Header />}
         <div className={`mian_content ${location.pathname =='/' && 'active'}`}>
           {location.pathname !='/'&& location.pathname !='/terms' && location.pathname !='/privacy' && <Sidebar />}
            <div className="content">
               <div className="custom_height">{props.children}</div>
               {location.pathname !='/' && location.pathname !='/terms' && location.pathname !='/privacy'&&  <Footer />}
            </div>
         </div>
      </>
   );
};
export default Admin;