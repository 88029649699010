import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';
import { Pagination } from '../../utils/pagination';

import { userServices } from "../../services";
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';
import { ALL_TRANSACTION_FILTER } from '../../utils/constant'
import { format } from 'date-fns';


const AllTransactions = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: globalData.pageSelected || 0,
        totalpages: 0,
        totalRecord: 0
    });
    const [isSearchStart, setIsSearchStart] = useState(false);


    useEffect(() => {
        allGameplans();
    }, [])

    useEffect(() => {
        if (isPaginationTrigger) {
            allGameplans();
        }
    }, [isPaginationTrigger]);

    function allGameplans() {
        let params = {
            page: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
            status: globalData.visibilityStatusFilter
        };
        userServices.GetAllTransactions(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data);

                handlePagination('page', parseInt(paginationObj.page));
                handlePagination('totalpages', Math.ceil(res.data.totalCount / LIMIT_PER_PAGE));
                handlePagination('totalRecord', res.data.totalCount);

                setIsPaginationTrigger(false);
            }
            setLoading(false);
        }).catch(error => {
            setIsPaginationTrigger(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        handlePagination('page', parseInt(event.selected));
        globalData.setPageSelected(parseInt(event.selected));
        setLoading(true);
        setIsPaginationTrigger(true);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        handlePagination('page', 0);
        setLoading(true);
        setIsPaginationTrigger(true);
    }


    const resetSearch = () => {
        setSearchKeyword("");
        // setIsSearchStart(false)
        handlePagination('page', 0);
        setLoading(true);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    const handlePagination = (type, value) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                [type]: value
            }
        });
    }
    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>

                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">All Transactions</h2>
                                            </Col>
                                            <Col sm={2} className="filters-style">
                                                Filter by status:
                                                <Dropdown onSelect={(e) => {
                                                    globalData.setVisibilityStatusFilter(e);
                                                    setLoading(true);
                                                    setIsPaginationTrigger(true);
                                                }}>
                                                    <Dropdown.Toggle>
                                                        {globalData.visibilityStatusFilter === "MONTH" ? "Month"
                                                            :
                                                            globalData.visibilityStatusFilter === "WEEK" ? "Week"
                                                                :
                                                                globalData.visibilityStatusFilter === "YEAR" ? "Year"
                                                                    :
                                                                    "All"
                                                        }
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            ALL_TRANSACTION_FILTER.map((value, index) => {
                                                                return (
                                                                    <Dropdown.Item key={`${value.value}_${index}`} eventKey={value.value} className={globalData.visibilityStatusFilter === value.value ? 'theme-background-color' : ''}>{value.title}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                        {/* <Dropdown.Item eventKey="" className={globalData.visibilityStatusFilter === "" ? 'theme-background-color' : ''}>All</Dropdown.Item>
                                                        <Dropdown.Item eventKey="MONTH" className={globalData.visibilityStatusFilter === "MONTH" ? 'theme-background-color' : ''}>Month</Dropdown.Item>
                                                        <Dropdown.Item eventKey="WEEK" className={globalData.visibilityStatusFilter === "WEEK" ? 'theme-background-color' : ''}>Week</Dropdown.Item>
                                                        <Dropdown.Item eventKey="YEAR" className={globalData.visibilityStatusFilter === "YEAR" ? 'theme-background-color' : ''}>Year</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                            <Col sm={4}>
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }} className="search-box">
                                                    <input type="text" placeholder='Search by Title/Description' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Gameplan Title</th>
                                                <th>Booked By</th>
                                                <th>Event Date</th>
                                                <th>Payment Status</th>
                                                <th>Price</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameplans.length ?
                                                gameplans.map((gameplan, key) => {
                                                    return (
                                                        <tr id={key}>
                                                            <td className="table-data first">
                                                                {console.log(">>>>>>>>>>>>>>>>", gameplan.gameplan.gameplan)}
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan.gameplan.title}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.gameplan.gameplan.title}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.user.first_name + " " + gameplan.user.last_name}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.user.first_name + " " + gameplan.user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.gameplan.booking_date), 'MM/dd/yyyy')}
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan.payment_status}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.gameplan.payment_status}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">${gameplan.transaction_amount}</Tooltip>
                                                                    }>
                                                                    <span>${gameplan.transaction_amount}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id="btn-view-1">View Gameplan Details</Tooltip>
                                                                }>
                                                                    <Link to={{
                                                                        pathname: `/transactionDetails/${gameplan._id}`,
                                                                        state: {
                                                                            type: 'allTransactions'
                                                                        }
                                                                    }}><i className="fa fa-eye view-eye" aria-hidden="true" /></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination handlePageChange={handlePageChange} currentPage={paginationObj.page} totalPages={paginationObj.totalpages} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default AllTransactions;