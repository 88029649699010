import React from 'react'

const LIST = ['Acknowledgement of Age', ' Definitions', ' Services', ' Assumption of Risk', ' Code of Conduct Copyright and Trademark', ' Account Information', ' Privacy Policy', ' Price and Availability', ' Refund Policy', ' Updates', ' Third Party Materials', ' Disclaimer of Warranties', ' Limitation of Liability', ' Arbitration Agreement and Class Action Waiver', ' Indemnification', ' Severability', ' Applicable Law', ' Contacting the Company', ' Applicability']


function TermsAndCondition() {
    return (
        <React.Fragment>
            <section className='terms-condition-section'>
                <div className='container'>
                    <div className='main-heading'>
                        <p className='my-3'>Last Updated: February 2023</p>
                    </div>
                    <div className='blog-content'>
                        <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. WHEN YOU USE OUR APP, AS DEFINED BELOW, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT THIS AGREEMENT AND OTHER POSTED POLICIES THEN DO NOT USE THE APP, DO NOT PROVIDE ANY INFORMATION TO THE APP, AND DO NOT PURCHASE ANY PRODUCTS OR SERVICES ON THE APP.</p>
                    </div>
                    <div className='blog-content'>
                        <p>THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT WAIVE YOUR RIGHT TO A COURT HEARING OR JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION SECTION BELOW.</p>
                    </div>

                    <p>These Terms and Conditions (the “Agreement”) are hereby effective this 1st day of February, 2023, and will apply to the app you are using [N8tive Nightlife App] and its related website, N8tiveNightlife.com,   (collectively, the “App”), which is owned and operated by N8TIVE NIGHTLIFE, LLC (“NNL” or the “Company”).</p>
                    <h3>TABLE OF CONTENTS</h3>
                    {
                        LIST.map((value, index) => {
                            return (
                                <div key={`${value}_${index}`}>{`${index + 1}. ${value}.`}</div>
                            )
                        })
                    }

                    <div className='blog-content mt-2'>
                        <h4>1. Acknowledgement of Age.</h4>
                        <p>By using the Company’s Services, including the App and any application or website owned or operated by the Company, You acknowledge and affirm You are at least 21 years of age. If You are less than 21 years of age you must immediately exit the App and cease using the Company’s Services.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>2. Definitions.</h4>
                        <p>In addition to those terms defined elsewhere in this Agreement, the following terms shall have the meanings set forth here:</p>
                        <p>“Feature” shall mean any attribute, perk, resource, or other feature utilized in conjunction with or separate from the App or Services, including the use of any Services on the App or any website, application, or other service operated by Company (including any third-parties, affiliates, or vendors), whether or not purchased by You, given to You, or otherwise obtained through your use of the Services.</p>
                        <p>“Hosts” offer hosting and planning services, activities, excursions, and events and a variety of travel and other services (collectively, “Experiences”) through the App. </p>
                        <p>“N8TIVE NIGHTLIFE” or “NNL” shall mean N8TIVE NIGHTLIFE, LLC, including any successors, administrators, agents, employees, and assigns.</p>
                        <p>“Services” shall mean any website, application, server use, computer program, third-party service, software, widget, tool, or any other service provided by NNL for your use.</p>
                        <p> “You” or “Your” shall mean you, the individual user of this service, and holder of any account, as applicable, including your agents, assigns, parents or guardians if under 18 years of age, or any person acting on your behalf, including guardians, heirs, or statutory beneficiaries.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>3. Services</h4>
                        <p>NNL offers a range of services, including access to content, forums, features, and other products and services, including those generated by third parties and user-generated content (including potential and actual content, forums, and other user created content, regardless of form), whether available on the world wide web or on another server or platform operated by NNL or any other server or site to which Services are licensed or directly provided by NNL.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>4. Responsibilities and Assumption of Risk.</h4>
                        <p>You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone you invite to join or provide access to any Experience. For example, this means: (i) you are responsible for paying all reasonable amounts necessary to cover damage that you, your guest(s), or your pet(s) cause curing an Experience, and (ii) you must act with integrity, treat others with respect, and comply with applicable laws at all times. If you are booking for an additional guest who is a minor or if you bring a minor to an Experience, you must be legally authorized to act on behalf of the minor and you are solely responsible for the supervision of that minor.</p>
                        <p>You acknowledge that many activities carry inherent risks and agree that, to the maximum extent permitted by applicable law, you assume the entire risk arising out of your access to and use of the App and the Services, including your participation in any Experience, or any other interaction you have with other users or Hosts whether in person or online. This means it is your responsibility to investigate an Experience to determine whether it is suitable for you. For example, Experiences may carry risk of illness, bodily injury, disability, or death, and you freely and willfully assume those risks by choosing to participate in those Experiences.</p>

                    </div>

                    <div className='blog-content'>
                        <h4>5. Code of Conduct.</h4>
                        <p>Illegal or Abusive Usage is Strictly Prohibited: You must not abuse, harass, threaten, impersonate or intimidate other users of our any Services or any employee, agent, or affiliate of NNL. You may not use the Company’s Services for any illegal or unauthorized purpose. International users agree to comply with all local laws regarding online conduct and acceptable content. NNL reserves the right, but is not obligated, to monitor materials posted in any public area and shall have the right to remove any information deemed offensive by our staff. Notwithstanding the foregoing, you remain solely responsible for your use of any information contained on the site. Distribution of any user’s personal information, commonly known as doxing, is prohibited. International users agree to comply with all local laws regarding online conduct and acceptable content. NNL, including all applications, websites, and forums, shall be free of discrimination on the basis of race, ethnicity, religion, nationality, and sexual identity, and conduct not consistent with the same, including language that is obscene, threatening, bullying, sexually threatening, or invades the personal privacy of any user is a violation of the Code of Conduct. Should you be found to have engaged in illegal or abusive usage of our Services, or any violation of this Agreement or of any applicable code of conduct, NNL may suspend, terminate, or take other action regarding your account. NNL is the sole and final arbiter of whether conduct violates this Agreement. NNL assumes no responsibility or liability for any user conduct or NNL’s decision to take action or not to take action with respect to user conduct.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>6. Copyright and Trademark.</h4>
                        <p>Copyright: All content included on any site, application, or other Service of NNL, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of the Company protected by United States and international copyright laws, and, where applicable, United States and international trademark law. The compilation of all content on this site is the exclusive property of the Company and protected by U.S. and international copyright laws. All software used on this any site, application, or other Service of NNL is the property of Company or its software suppliers and protected by United States and international copyright laws. NNL owns and retains all proprietary rights to this intellectual property, wherever located, including whether located on any Device, website, domain, server, or other location, including its applications, as well as its trademarks and copyrights. Except for any information that is in the public domain, You are not authorized to reproduce, transmit, or distribute the proprietary information of any Service provided to You by MGB. By posting information to the App or any website, application, or any other service operated by NNL, You represent to NNL You have the right to grant permission for use of the same by NNL.</p>

                    </div>

                    <div className='blog-content'>
                        <h4>7. Account Information.</h4>
                        <p>To utilize our Services, You may be required to create an account on the App (which may include, but is not limited to, you providing associated user names, user identification, guild names, or any other unique identifier) which will be used to engage with our Services (an”Account”). NNL has the sole right to set requirements for obtaining an Account, or to alter, cancel, suspend, or terminate any Account. You do not own the Account, or any associated content, including achievements, content, or other Features but are granted a revocable limited license to use the account, consistent with this Agreement governing any use of the the App and Services. You are responsible for maintaining the confidentiality of Your account and password and for restricting access to Your computer, and You agree to accept responsibility for all activities that occur under Your Account. You agree to notify NNL immediately of any unauthorized use of Your Account, including unauthorized use of any userid or associated unique identifiers.</p>
                        <p>As the account holder, You are responsible for all charges incurred, including applicable taxes, and all purchases made by You or anyone that uses Your Account with or without Your express authorization, including family and friends. This responsibility includes payments made through any third-party service utilized to facilitate purchases. Any use of third-party services to facilitate transaction, including purchases of the Services, shall be governed, in addition to this Agreement, by the terms of service of the third-party service through which the transaction was facilitated.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>8. Privacy Policy.</h4>
                        <p>In addition to the terms and conditions of this Agreement, You acknowledge and agree to be bound by the terms of NNL’s Privacy Policy, which is available for review at _____________________________________. In addition, You acknowledge and agree your use of the Application is governed by and subject to said Privacy Policy.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>9. Price and Availability.</h4>
                        <p>NNL shall have the right, prior to acceptance of any particular order, to set or modify any terms of sale, including pricing and discounts, or to make any such modifications for any reason, including increased demand, changes in any costs, transaction, tariffs, exchange rates, or for any other reason. Any such order shall be subject to availability, including limited availability of digital products or other features, which may be set by NNL at its sole discretion. NNL reserves the right to alter or amend any product description, providing the opportunity to cancel or modify the order, or to accept the order with a revised description. You shall be responsible for shipping costs of any physical product. Prices are listed in United States Dollars, and You shall be responsible for the effective currency conversion rate in place at the time of your sale. For purchases outside the United States, Your banking or credit provider may charge additional fees for such conversion of currency. </p>
                        <p>You further agree that provision of digital products or other Features shall be due only once a confirmation email regarding your transaction is sent, unless the product is a pre-order or an order of Features prior to the release of the Services to which the Features relate, or unless the product is a pre-order for the Services, in which case delivery shall be due upon delivery of the pre-ordered product. You acknowledge that times and dates of deliveries within any order are solely estimates. NNL shall not be liable for any delay in delivery of any shipment or digital product. For delivery of physical product, an item shall be deemed delivered once shipped by NNL, and all title and risk of loss shall pass to You.</p>

                    </div>

                    <div className='blog-content'>
                        <h4>10. Refund Policy.</h4>
                        <p>Payments made through the App are generally non-refundable. However, we may provide refunds on a case by case basis where appropriate. Users who have previously received a refund should not expect to receive a future refund. Payments made for Experiences are non-refundable after the Experience has occurred. </p>
                    </div>


                    <div className='blog-content'>
                        <h4>11. Updates.</h4>
                        <p>NNL may, from time to time and in its sole discretion, develop and provide Application updates or amend the Services provided, which may include upgrades, bug fixes, patches, other error corrections, and/or new Features (collectively, including related documentation, "Updates"). Updates may also modify or delete in their entirety certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. </p>
                    </div>


                    <div className='blog-content'>
                        <h4>12. Third-Party Materials.</h4>
                        <p>This website, or other sites, applications, or services offered by NNL may display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising (collectively, "Third-Party Materials"). You acknowledge and agree that Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability or responsibility to You or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to You, and You access and use them entirely at Your own risk and subject to such third parties' terms and conditions.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>13. Disclaimer of Warranties.</h4>
                        <p>ANY WEBSITE, APPLICATION, OR OTHER SERVICE, AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND SERVICES INCLUDED IN THE APP OR OTHERWISE MADE AVAILABLE TO YOU BY NNL ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE APP OR ANY WEBSITE, APPLICATION, OR OTHER SERVICE, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY WEBSITE, APPLICATION, OR OTHER SERVICE OPERATED BY NNL, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF ANY SUCH WEBSITE, APPLICATION, OR OTHER SERVICE IS AT YOUR SOLE RISK.</p>
                        <p>TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT WARRANT THAT ANY WEBSITE, APPLICATION, OR OTHER SERVICE OPERATED OR PROVIDED BY NNL; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE APP OR ANY WEBSITE, APPLICATION, OR OTHER SERVICE OPERATED OR PROVIDED BY NNL; THEIR SERVERS; OR E-MAIL SENT FROM COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE APP OR ANY WEBSITE, APPLICATION, OR SERVICE OPERATED OR PROVIDED BY NNL, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE APP OR ANY WEBSITE, APPLICATION, OR SERVICE OPERATED OR PROVIDED BY NNL, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</p>
                        <p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>
                    </div>


                    <div className='blog-content'>
                        <h4>14. Limitation of Liability.</h4>
                        <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE, UTILIZE, OR ACCESS THE CONTENT AND SERVICES FOR:</p>
                        <p>(a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.</p>
                        <p>(b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.</p>
                        <p>THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
                    </div>

                    <div className='blog-content'>
                        <h4>15. ARBITRATION AGREEMENT AND CLASS ACTION WAIVER.</h4>
                        <p>EXCEPT WHERE PROHIBITED BY LAW, YOU AND THE COMPANY AGREE THAT ALL CLAIMS, DISPUTES OR CONTROVERSIES BETWEEN YOU AND THE COMPANY (including disputes against any agent employee, subsidiary, affiliate, predecessor in interest, successor, or assign of the other), ITS PARENTS, AFFILIATES, SUBSIDIARIES OR RELATED COMPANIES, relating to the App, the Services provided by Company or on or through any website owned or operated by the Company, any transaction or relationship between us resulting from your use of the App, communications between us, or the purchase of the Company’s Services and materials INCLUDING, WITHOUT LIMITATION, TORT AND CONTRACT CLAIMS, CLAIMS BASED UPON ANY FEDERAL, STATE OR LOCAL STATUTE, LAW, ORDER, ORDINANCE OR REGULATION, AND THE ISSUE OF ARBITRABILITY, SHALL BE RESOLVED BY THE FINAL AND BINDING ARBITRATION PROCEDURES SET BELOW. THE PARTIES ACKNOWLEDGE AND AGREE THAT ANY SUCH CLAIMS SHALL BE BROUGHT SOLELY IN THE PARTY'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE PROCEEDING, OR PRIVATE ATTORNEY GENERAL CAPACITY. THE PARTIES FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THE PARTIES VOLUNTARILY AND KNOWINGLY WAIVE ANY RIGHT THEY HAVE TO A JURY TRIAL. ANY CONTROVERSY CONCERNING WHETHER A DISPUTE IS ARBITRABLE SHALL BE DETERMINED BY THE ARBITRATOR AND NOT BY THE COURT. JUDGMENT UPON ANY AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED BY A STATE OR FEDERAL COURT HAVING JURISDICTION THEREOF. THIS ARBITRATION CONTRACT IS MADE PURSUANT TO A TRANSACTION IN INTERSTATE COMMERCE AND ITS INTERPRETATION, APPLICATION, ENFORCEMENT AND PROCEEDINGS HEREUNDER SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT ("FAA").</p>
                        <p>The following procedures shall apply:</p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>

                        <p>In the event a party elects to proceed with binding arbitration, it shall provide written notice thereof to the other party by registered or certified mail and shall describe in such notice, with reasonable particularity, the nature and basis of such claim and the total amount of the claim. Within thirty (30) days of receipt of such notice, the party receiving notice of a claim shall provide a written response which, with reasonable particularity, sets forth its position concerning the claim. If the parties are unable to resolve the dispute arising from the claim by good faith negotiations to be conducted within the thirty (30)-day period following the written response, either of them may initiate binding arbitration pursuant to the terms and conditions set forth herein. Arbitration shall be administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules.</p>
                        <p>BY AGREEING TO THIS ARBITRATION AGREEMENT, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT, INCLUDING YOUR RIGHT TO A JURY TRIAL. In arbitration, a dispute is resolved by a neutral arbitrator or panel of arbitrators, rather than by a judge or jury. Arbitration is more informal than a court trial; however, an arbitrator can award the same relief that a court can award.</p>
                        <p>Separate and apart from the agreement to arbitrate set forth above, the parties hereby independently waive any right to bring or participate in any class action in any way related to, or arising from, this agreement. You acknowledge that this class action waiver is material and essential to the arbitration of any disputes between the parties and is nonseverable from the agreement to arbitrate claims. If any portion of this class action waiver is limited, voided, or cannot be enforced, then the parties' agreement to arbitrate shall be null and void. YOU UNDERSTAND THAT BY AGREEING TO THIS ARBITRATION AGREEMENT, WHICH CONTAINS THIS CLASS ACTION WAIVER, YOU MAY ONLY BRING CLAIMS AGAINST THE COMPANY, ITS AGENTS, OFFICERS, SHAREHOLDERS, MEMBERS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS AND/OR ASSIGNS IN AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.</p>
                        <p>Agreeing to this Arbitration Provision is not a mandatory condition of your contractual relationship with the Company. If you do not want to be subject to this Arbitration Provision, you may opt out of this Arbitration Provision. To do so, within 30 days of the date that this Agreement is electronically accepted by you, you must send a written notice to the Company’s address set forth herein stating your intent to opt out of this Arbitration Provision, as well as your name, address and the email associated with your account. A letter sent by your agent or representative (including your counsel) shall not be effective. Your letter may opt out yourself only, and any letter that purports to opt out anyone other than yourself shall be void as to any others. Should you not opt out of this Arbitration Provision within the 30-day period, you and the Company shall be bound by the terms of this Arbitration Provision.</p>
                        <p>You will not be subject to retaliation if you exercise your right to opt out of this Arbitration Provision. If you opt out of this Arbitration Provision and at the time of your receipt of this Agreement you were bound by an existing agreement to arbitrate disputes arising out of or related to your use of the Websites, that existing arbitration agreement will remain in full force and effect. Neither your acceptance of this Agreement nor your decision to opt out of this Arbitration Provision will affect any obligation you have to arbitrate disputes not specified in this Arbitration Provision pursuant to any other agreement you have with the Company.</p>

                    </div>

                    <div className='blog-content'>
                        <h4>16. Indemnification. </h4>
                        <p>You agree to indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, arising from or relating to Your use or misuse of the App or Your breach of this Agreement, including but not limited to the content you submit or make available through this App.
                        </p>
                    </div>

                    <div className='blog-content'>
                        <h4>17. Severability.</h4>
                        <p>If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</p>
                    </div>


                    <div className='blog-content'>
                        <h4>18. Applicable Law </h4>
                        <p>By visiting or using the App, our other websites, or other Services, You agree that the laws of the State of Washington, without regard to principles of conflict of laws, will govern this Agreement and any dispute of any sort that might arise between You and Company. Any lawsuit enforcing an arbitration award arising from this Agreement or the use of our Services shall be brought exclusively before the State or Federal Courts located in King County, Washington.</p>
                    </div>

                    <div className='blog-content'>
                        <h4>19. Contacting the Company.</h4>
                        <p>You may contact the Company by phone, email, or mail at the following:</p>
                        <p>Phone: </p>
                        <p>Email:</p>
                        <p>Mail:</p>
                    </div>

                    <div className='blog-content'>
                        <h4>20. Applicability.</h4>
                        <p>You agree that this Agreement supersedes and cancels all previous contracts or agreements, whether oral, written, or in some other form, whether express or implied, or whether through any prior course of dealing. You agree that NNL, from time to time, may amend this Agreement. You further agree it is Your responsibility to read and comprehend this Agreement, and You agree to be bound by them.</p>
                    </div>
                </div>

            </section>

        </React.Fragment>
    )
}

export default TermsAndCondition;