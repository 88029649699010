import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { context } from '../../GlobalContext/GlobalContext';

const Sidebar = () => {
  const globalData = useContext(context);
  const [isOpen, setIsopen] = useState(false);
  let history = useHistory();

  const toggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  var path = window.location.pathname;

  useEffect(() => {
    if (path === '/userDashboard') {
      localStorage.setItem('active_sub_tab', "userDashboard");
      globalData.setSubTab("userDashboard");
      globalData.setMainTab(localStorage.getItem('active_tab') || "userDashboard");
    }
    else if (path === '/unapproved') {
      localStorage.setItem('active_sub_tab', "native_users");
      globalData.setSubTab("native_users");
    } else if (path === '/approved') {
      localStorage.setItem('active_sub_tab', "native_users_approved");
      globalData.setSubTab("native_users_approved");
    } else if (path == '/nb') {
      localStorage.setItem('active_sub_tab', "newbie_users");
      globalData.setSubTab("newbie_users");
    } else if (path == '/incomplete') {
      localStorage.setItem('active_sub_tab', "incomplete_profile_users");
      globalData.setSubTab("incomplete_profile_users");
    } else if (path === '/payout') {
      globalData.setPageSelected(0);
      globalData.setSubTab("payout");
      globalData.setVisibilityStatusFilter('')
      globalData.setPaymentStatusFilter("")
      localStorage.setItem('active_sub_tab', "payout");
      globalData.setMainTab("allTransactions")
      globalData.setUserTypeTab("payout");
      history.push('/payout');
    } else if (path === '/bookingPayment') {
      globalData.setPageSelected(0);
      globalData.setVisibilityStatusFilter('')
      globalData.setPaymentStatusFilter("")
      globalData.setSubTab("bookingPayment");
      localStorage.setItem('active_sub_tab', "bookingPayment");

      globalData.setMainTab("allTransactions")
      globalData.setUserTypeTab("bookingPayment");
      history.push('/bookingPayment');
    } else if (path === '/gameplans') {
      globalData.setPageSelected(0);
      globalData.setVisibilityStatusFilter('')
      globalData.setPaymentStatusFilter("")
      globalData.setMainTab("gameplans");
      localStorage.setItem('active_tab', "gameplans");
      globalData.setUserTabOpen(false);
      history.push('/gameplans');
      globalData.setUserTypeTab("");
    } else {
      globalData.setMainTab(localStorage.getItem('active_tab') || "all_users");
    }
  }, [path]);

  function AllUsersTab() {

    if (isOpen) {
      setIsopen(false);
      globalData.setUserTabOpen(false);
      globalData.setMainTab("all_users");
    } else {
      globalData.setUserTypeTab("unapproved");
      globalData.setMainTab("all_users");
      globalData.setSubTab("native_users");
      globalData.setUserTabOpen(!globalData.userTabOpen);
      localStorage.setItem('active_tab', "all_users");
      localStorage.setItem('active_sub_tab', "native_users");
      history.push('/unapproved');
    }
  }

  function AllGamePlans() {
    globalData.setPageSelected(0);
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("gameplans");
    localStorage.setItem('active_tab', "gameplans");
    globalData.setUserTabOpen(false);
    history.push('/gameplans');
    globalData.setUserTypeTab("");
  }

  function AllBoking() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("booking")
    localStorage.setItem('active_tab', "booking")
    globalData.setUserTabOpen(false)
    history.push('/booking')
    globalData.setUserTypeTab("");
  }

  function SuspendedGameplan() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("suspendedGameplan")
    localStorage.setItem('active_tab', "suspendedGameplan")
    globalData.setUserTabOpen(false)
    globalData.setUserTypeTab("");
    history.push('/suspendedGameplan')
  }

  function ContactUs() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("contactUs")
    localStorage.setItem('active_tab', "contactUs")
    globalData.setUserTabOpen(false)
    globalData.setUserTypeTab("")
    history.push('/contactUs')
  }
  function UserDashboard() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("userDashboard")
    localStorage.setItem('active_tab', "userDashboard")
    globalData.setUserTabOpen(false)
    globalData.setUserTypeTab("")
    history.push('/userDashboard')
  }

  function TransactionDashboard() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("transactionDashboard")
    localStorage.setItem('active_tab', "transactionDashboard")
    globalData.setUserTabOpen(false)
    globalData.setUserTypeTab("")
    history.push('/transactionDashboard')
  }

  function commission() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setMainTab("commission")
    localStorage.setItem('active_tab', "commission")
    globalData.setUserTabOpen(false)
    globalData.setUserTypeTab("")
    history.push('/commission')
  }

  function NativeUsers() {
    globalData.setPageSelected(0);
    globalData.setNativeUsersTabOpen(!globalData.nativeUsersTabOpen);
    globalData.setSubTab("native_users");
    // isOpen === true ? setIsopen(false) : setIsopen(true);
  }


  function allTransactions() {
    globalData.setPageSelected(0)
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setTransactionsTabOpen(!globalData.transactionsTabOpen);
    globalData.setMainTab("allTransactions")
    globalData.setUserTypeTab("bookingPayment");
    globalData.setSubTab("bookingPayment");
  }

  function NativeApprovedUsers() {
    globalData.setPageSelected(0);
    globalData.setSubTab("native_users");
    globalData.setUserTypeTab("approved");
    localStorage.setItem('active_sub_tab', "native_users_approved");
    history.push('/approved');
    // setIsopen(isOpen === true ? false : true);
    // isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  function NativeUnapprovedUsers() {
    globalData.setPageSelected(0);
    globalData.setSubTab("native_users");
    globalData.setUserTypeTab("unapproved");
    localStorage.setItem('active_sub_tab', "native_users");
    history.push('/unapproved');
    // isOpen === true ? setIsopen(false) : setIsopen(true);
    // setIsopen(isOpen === true ? false : true);
  }


  function BookingPayment() {
    globalData.setPageSelected(0);
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setSubTab("bookingPayment");
    localStorage.setItem('active_sub_tab', "bookingPayment");

    globalData.setMainTab("allTransactions")
    globalData.setUserTypeTab("bookingPayment");
    history.push('/bookingPayment');
  }
  function HoldPaymentList() {
    globalData.setPageSelected(0);
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    globalData.setSubTab("holdPayment");
    localStorage.setItem('active_sub_tab', "holdPayment");
    globalData.setMainTab("allTransactions")
    globalData.setUserTypeTab("holdPayment");
    history.push('/holdPayment');
  }
  function Payout() {
    globalData.setPageSelected(0);
    globalData.setSubTab("payout");
    globalData.setVisibilityStatusFilter('')
    globalData.setPaymentStatusFilter("")
    localStorage.setItem('active_sub_tab', "payout");

    globalData.setMainTab("allTransactions")
    globalData.setUserTypeTab("payout");
    history.push('/payout');
  }

  function NewbieUsers() {
    globalData.setPageSelected(0);
    globalData.setUserTypeTab("");
    globalData.setSubTab("newbie_users");
    localStorage.setItem('active_sub_tab', 'newbie_users');
    history.push('/nb');
    // isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  function IncompleteProfileUsers() {
    globalData.setPageSelected(0);
    globalData.setUserTypeTab("");
    globalData.setSubTab("incomplete_profile_users");
    localStorage.setItem('active_sub_tab', 'incomplete_profile_users');
    history.push('/incomplete');
    // isOpen === true ? setIsopen(false) : setIsopen(true);
  }


  return (
    <>
      <button className="barbtn" onClick={toggleSidebar}><i className="fa fa-bars" /></button>
      <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={toggleSidebar}></div>
      <section className={`sidebar ${isOpen == true ? 'active' : ''}`}>
        <div className={globalData.mainTab == "userDashboard" ? "active" : ""} onClick={UserDashboard}>
          <span className="sidebar_option">
            Dashboard
          </span>
        </div>
        <div className={globalData.mainTab == "all_users" ? "active" : ""} onClick={AllUsersTab}>
          <span className="sidebar_option">User Management<img className={globalData.userTabOpen ? 'rotate' : ''} src={require("../../assets/images/Stroke1.png")} /></span>
        </div>
        {globalData.userTabOpen
          ?
          <>
            <div className={globalData.subTab == "native_users" ? "active-sub" : ""} onClick={NativeUsers}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                N8tive Users
                <img className={globalData.nativeUsersTabOpen ? 'rotate' : ''} src={require("../../assets/images/Stroke1.png")} />
              </span>
            </div>
            {globalData.nativeUsersTabOpen
              ?
              <>
                <div className={globalData.userTypeTab == "approved" ? "active-sub-option" : "sub-inactive"} onClick={NativeApprovedUsers}>
                  <span className="sidebar_option">
                    Verified Users
                  </span>
                </div>
                <div className={globalData.userTypeTab == "unapproved" ? "active-sub-option" : "sub-inactive"} onClick={NativeUnapprovedUsers}>
                  <span className="sidebar_option">
                    Unverified Users
                  </span>
                </div>
              </>
              :
              <></>
            }
            <div className={globalData.subTab == "newbie_users" ? "active-sub" : ""} onClick={NewbieUsers}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Newbie Users
              </span>
            </div>

            <div className={globalData.subTab == "incomplete_profile_users" ? "active-sub" : ""} onClick={IncompleteProfileUsers}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Incomplete Profiles
              </span>
            </div>
          </>
          :
          null
        }
        <div className={globalData.mainTab == "gameplans" ? "active" : ""} onClick={AllGamePlans}>
          <span className="sidebar_option">
            All Gameplans
          </span>
        </div>
        <div className={globalData.mainTab == "booking" ? "active" : ""} onClick={AllBoking}>
          <span className="sidebar_option">
            All Bookings
          </span>
        </div>
        <div className={globalData.mainTab == "suspendedGameplan" ? "active" : ""} onClick={SuspendedGameplan}>
          <span className="sidebar_option">
            Suspended Gameplan
          </span>
        </div>

        <div className={globalData.mainTab == "allTransactions" ? "active" : ""} onClick={allTransactions}>
          <span className="sidebar_option">
            All Transactions
            <img className={globalData.transactionsTabOpen ? 'rotate' : ''} src={require("../../assets/images/Stroke1.png")} />
          </span>
        </div>
        {globalData.transactionsTabOpen
          ?
          <>

            <div className={globalData.userTypeTab == "bookingPayment" ? "active-sub" : ""} onClick={BookingPayment}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Booking Payment
              </span>
            </div>


            <div className={globalData.userTypeTab == "payout" ? "active-sub" : ""} onClick={Payout}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Payouts
              </span>
            </div>
            <div className={globalData.userTypeTab == "holdPayment" ? "active-sub" : ""} onClick={HoldPaymentList}>
              <span className="sidebar_option users-area">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Hold Payment
              </span>
            </div>
          </>
          :
          <></>
        }
        <div className={globalData.mainTab == "transactionDashboard" ? "active" : ""} onClick={TransactionDashboard}>
          <span className="sidebar_option">
            Insights
          </span>
        </div>
        <div className={globalData.mainTab == "commission" ? "active" : ""} onClick={commission}>
          <span className="sidebar_option">
            Commission Management
          </span>
        </div>

        <div className={globalData.mainTab == "contactUs" ? "active" : ""} onClick={ContactUs}>
          <span className="sidebar_option">
            Contact Us
          </span>
        </div>



      </section>
    </>
  )
}
export default Sidebar;