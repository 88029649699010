import React, { useState, useEffect, } from 'react';
import { userServices } from '../../services';
import Loader from '../common/Loader';
import { useHistory } from "react-router-dom";


const UserDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState()
    const history = useHistory();

    useEffect(() => {
        getUserCount()
    }, [])

    function getUserCount() {
        setLoading(true);
        userServices.getUserCount().then((res) => {
            if (res.status === 200) {
                setUserData(res?.data?.data)
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }
    return (
        <>
            {loading && <Loader />}
            <>

                <div className="top3_area">
                    <div className="row">
                        <aside className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "#0cc2aa" }}>{userData?.nativeCount}</h3>
                                <p className="text-uppercase">TOTAL NATIVE USERS</p>
                            </div>
                        </aside>
                        <aside className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "rgba(255,119,80,10)" }}>{userData?.newbieCount}</h3>
                                <p className="text-uppercase">TOTAL NEWBIE USERS</p>
                            </div>
                        </aside>
                        <aside onClick={() => history.push("/gameplans")} className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "rgba(35, 84, 99, 10)" }}>{userData?.gameplanCount}</h3>
                                <p className="text-uppercase">TOTAL GAMEPLANS</p>
                            </div>
                        </aside>
                        <aside className="col-lg-3">
                            <div className="white_three">
                                <h3 style={{ color: "rgb(151 23 157)" }}>{userData?.todayBooking}</h3>
                                <p className="text-uppercase">TODAY'S BOOKINGS</p>
                            </div>
                        </aside>
                    </div>
                </div>
            </>
        </>
    )
}
export default UserDashboard;