import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { context } from '../../GlobalContext/GlobalContext';
import { userServices } from '../../services';
import Loader from '../common/Loader';
import Modal from 'react-bootstrap/Modal';

const ContactForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [contactUsData, setContactUsData] = useState({});
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        contactUsDetails()
    }, [])

    function contactUsDetails() {
        let params = {
            contact_id: id,
        }
        setLoading(true);
        userServices.GetContactUsDetails(params).then((res) => {
            if (res.status == 200) {
                setContactUsData(res.data.contactUsForm)
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }
    const onSubmit = () => {
        if (message) {
            let params = {
                contact_id: id,
                data: {
                    "reply_message": message,
                    "status": true,
                }
            }
            setLoading(true);

            userServices.PutContactMessage(params).then((res) => {
                if (res.status == 200) {
                    contactUsDetails()
                }

                if (res.data.message) {
                    swal("Success", "Your response is sent successfully", "success");
                    // swal("Success", res.data.message, "success");
                }

                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    localStorage.clear()
                    history.push("/")
                } else {
                    swal("Error", "Server error", "error")
                }
            })


        }

    }
    return (
        <>

            <>
                {loading && <Loader />}
                <div className='sale_graph transaction_box mb-3'>
                    <Modal
                        size="md"
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered

                    >
                        <form onSubmit={(event) => {
                            event.preventDefault()
                            setModalShow(false);
                            onSubmit()
                        }} autoComplete="off">
                            <Modal.Body >
                                <div className='row justify-content-center'>
                                    <aside className='col-md-12'>
                                        <label className="form-label" htmlFor="message">
                                            Message
                                        </label>
                                        <textarea onChange={(e) => { setMessage(e.target.value) }} className="form-control" id="message" required />
                                    </aside>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" className='send-btn'>Send</Button>
                                <Button type="reset" onClick={() => {
                                    setModalShow(false);
                                    setMessage("");
                                }} className='send-btn cancle-btn'>Cancel</Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    <div className='bact-div'>
                        <Button onClick={() => {
                            history.location.state ? history.push(`/${history.location.state.type}`) : history.goBack()
                        }} className='back-bt' variant="unset"> <i className="fa fa-angle-left" aria-hidden="true"></i>  Back</Button>
                    </div>
                    <Row>
                        <Col>
                            <h2 className='mb-2'>Contact Details</h2>
                        </Col>

                    </Row>
                    <div className='contact-view-details'>
                        <div className='row'>
                            <aside className='col-md-6'>
                                <div>
                                    <span>Name</span>
                                    <h3>{contactUsData.firstName ? contactUsData.firstName + " " + contactUsData.lastName : ''}</h3>
                                </div>
                            </aside>
                            <aside className='col-md-6'>
                                <div className='contact-txt'>
                                    <span>Email Address</span>
                                    <h3>{contactUsData.email}</h3>
                                </div>
                            </aside>
                            <aside className='col-md-6'>
                                <div className='contact-txt'>
                                    <span>Subject</span>
                                    <h3>{contactUsData.subject}</h3>
                                </div>
                            </aside>
                            <aside className='col-md-6'>
                                <div className='contact-txt'>
                                    <span>Message</span>
                                    <h3>{contactUsData.message}</h3>
                                </div>
                            </aside>
                            <div className="mb-3">
                                {/* <label className="form-label" htmlFor="message">
                                    Message
                                </label>
                                <textarea className="form-control" id="message" required /> */}
                            </div>
                            {contactUsData.status ?
                                <aside className='col-md-6'>
                                    <div className='contact-txt'>
                                        <span>Reply</span>
                                        <h3>{contactUsData?.reply_message}</h3>
                                    </div>
                                </aside>
                                :
                                <aside className='col-md-3'>
                                    <button onClick={() => setModalShow(true)} className="btn btn-danger reply-btn" type="submit">
                                        Reply
                                    </button>
                                </aside>}
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}
export default ContactForm