import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";

import { userServices } from "../../services";
import Loader from '../common/Loader';
import { Container, Row, Col, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';

import { format } from 'date-fns';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';
import { Pagination } from '../../utils/pagination';


const Payout = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalpages: 0,
        totalRecord: 0
    });

    useEffect(() => {
        allGameplans()
    }, []);

    useEffect(() => {
        if (isPaginationTrigger) {
            allGameplans();
        }
    }, [isPaginationTrigger]);

    function allGameplans() {
        setLoading(true)
        let params = {
            page: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
            status: globalData.visibilityStatusFilter
        };
        userServices.GetTransactionsPayout(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data)
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.totalCount
                    }
                });
                // setCurrentPage(parseInt(paginationObj.page))
                setIsPaginationTrigger(false);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        handlePagination('page', parseInt(event.selected));
        setLoading(true);
        // globalData.setPageSelected(parseInt(event.selected))
        setIsPaginationTrigger(true);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        setLoading(true);
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
        // allGameplans()
    }

    const resetSearch = () => {
        setSearchKeyword("");
        // setIsSearchStart(false);
        setLoading(true);
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    // common function to handle pagination
    const handlePagination = (type, value) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                [type]: value
            }
        });
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>

                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">Payouts</h2>
                                            </Col>
                                            <Col sm={2} className="filters-style">
                                                Filter by status:
                                                <Dropdown onSelect={(e) => {
                                                    setLoading(true);
                                                    globalData.setVisibilityStatusFilter(e);
                                                    handlePagination('page', 0);
                                                    setIsPaginationTrigger(true);
                                                }}>
                                                    <Dropdown.Toggle>
                                                        {globalData.visibilityStatusFilter === "PENDING" ? "Pending"
                                                            :
                                                            globalData.visibilityStatusFilter === "SUCCESS" ? "Success"
                                                                :
                                                                "All"
                                                        }
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="" className={globalData.visibilityStatusFilter === "" ? 'theme-background-color' : ''}>All</Dropdown.Item>
                                                        <Dropdown.Item eventKey="PENDING" className={globalData.visibilityStatusFilter === "PENDING" ? 'theme-background-color' : ''}>Pending</Dropdown.Item>
                                                        <Dropdown.Item eventKey="SUCCESS" className={globalData.visibilityStatusFilter === "SUCCESS" ? 'theme-background-color' : ''}>Success</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Gameplan Title/Booked By' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>

                                                {/* <Form.Control placeholder='Search by Gameplan Title/Booked By' value={searchKeyword}
                                                    onChange={e => {
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                                {isSearchStart ?<i className="fa fa-close" aria-hidden="true" onClick={() => {
                                                        setSearchKeyword("")
                                                        setIsSearchStart(false)
                                                        setPaginationObj(prevPaginationObj => {
                                                            return {
                                                                ...prevPaginationObj,
                                                                page: 0,
                                                            }
                                                        });
                                                        setIsPaginationTrigger(true);
                                                    }} />:<i className="fa fa-search" aria-hidden="true" onClick={() => {
                                                        if (searchKeyword) {
                                                            setIsSearchStart(true)
                                                            allGameplans()
                                                        }
                                                    }} />
                                                } */}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Gameplan Title</th>
                                                <th>Booked By</th>
                                                <th>Event Date</th>
                                                <th>Payment Status</th>
                                                <th>Price</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameplans.length
                                                ?
                                                gameplans.map((gameplan, key) => {

                                                    return (
                                                        <tr key={`${gameplan?._id}_${key}`} id={key}>

                                                            <td className="table-data first">
                                                                {console.log(">>>>>>>>>>>>>>>>", gameplan.gameplan.gameplan)}
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan?.gameplan?.gameplan?.title}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan?.gameplan?.gameplan?.title}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.user.first_name + " " + gameplan.user.last_name}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan.user.first_name + " " + gameplan.user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.gameplan.booking_date), 'MM/dd/yyyy')}
                                                            </td>

                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan.payout_status}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>{gameplan.gameplan.payout_status}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">${gameplan.transaction_amount}</Tooltip>
                                                                    }
                                                                >
                                                                    <span>${gameplan.transaction_amount}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {console.log("bookingId", gameplan.bookingId)}
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-view-1">View Gameplan Details</Tooltip>
                                                                    }>
                                                                    <Link to={{
                                                                        pathname: `/payoutDetails/${gameplan.bookingId}`,
                                                                        state: {
                                                                            type: 'payout'
                                                                        }
                                                                    }}><i className="fa fa-eye view-eye" aria-hidden="true"></i></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination currentPage={paginationObj.page} totalPages={paginationObj.totalpages} handlePageChange={handlePageChange} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Payout;