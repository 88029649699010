import React from "react";

function Loader() {
    return (
        <div>
            <div className='loaderContainer content'>
                <div className='loader' />
            </div>
        </div>
    )
}

export default Loader;