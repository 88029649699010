import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { userServices } from "../../services";
import { Container, Row, Col, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';
import { format } from 'date-fns';
// import { DATE_OPTIONS, USER_TYPE } from '../../assets/constants/constants'
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';
import { ALL_BOOKING_FILTER } from '../../utils/constant';
import { Pagination } from '../../utils/pagination';
// CSS
import '../../assets/css/style.css';

const Booking = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalpages: 0,
        totalRecord: 0
    });


    useEffect(() => {
        bookingList()
    }, [])

    useEffect(() => {
        if (isPaginationTrigger) {
            bookingList();
        }
    }, [isPaginationTrigger])

    function bookingList() {
        let params = {
            page: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
            status: globalData.visibilityStatusFilter
        }
        userServices.GetAllBooking(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data)
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.totalCount
                    }
                });
            }
            setIsPaginationTrigger(false);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        handlePagination('page', parseInt(event.selected));
        setLoading(true);
        setIsPaginationTrigger(true);
        // globalData.setPageSelected(parseInt(event.selected))
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true);
        setLoading(true);
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
    }

    const resetSearch = () => {
        setSearchKeyword("");
        // setIsSearchStart(false);
        setLoading(true);
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    // common function to handle pagination
    const handlePagination = (type, value) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                [type]: value
            }
        });
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center'>
                                            <Col sm={6}>
                                                <h2 className="mb-2">All Bookings</h2>
                                            </Col>
                                            <Col sm={2} className="filters-style">
                                                Filter by status:
                                                <Dropdown onSelect={(e) => {
                                                    globalData.setVisibilityStatusFilter(e);
                                                    setLoading(true);
                                                    handlePagination('page', 0);
                                                    setIsPaginationTrigger(true);
                                                }}>
                                                    <Dropdown.Toggle>
                                                        {
                                                            globalData.visibilityStatusFilter === "CANCELLED" ? "Cancelled"
                                                                :

                                                                globalData.visibilityStatusFilter === "Complete" ? "Complete"
                                                                    :
                                                                    globalData.visibilityStatusFilter === "Upcoming" ? "Upcoming"
                                                                        : "All"
                                                        }
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            ALL_BOOKING_FILTER.map((value, index) => {
                                                                return (
                                                                    <Dropdown.Item eventKey={value.value} key={`${value.value}_${index}`} className={globalData.visibilityStatusFilter === value.value ? 'theme-background-color' : ''}>{value.title}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>

                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Title/Description' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>

                                                <th>Gameplan Title</th>
                                                <th>Booked By</th>
                                                <th>Booking Date</th>
                                                <th>Start Date</th>
                                                <th>Price</th>
                                                <th>Status</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameplans.length ?
                                                gameplans.map((gameplan, key) => {
                                                    console.log('gameplan', gameplan.booking, 'gameplan.booking_status', gameplan.booking_status)
                                                    return (
                                                        <tr key={`${gameplan._id}_${key}`} id={key}>

                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.gameplan.title}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.gameplan.title}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.user.first_name + " " + gameplan.user.last_name}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.user.first_name + " " + gameplan.user.last_name}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.createdAt), 'MM/dd/yyyy')}

                                                            </td>
                                                            <td>
                                                                {format(new Date(gameplan.booking_date), 'MM/dd/yyyy')}
                                                            </td>

                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">${gameplan.total_price}</Tooltip>
                                                                    }>
                                                                    <span>${gameplan.total_price}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="table-data first">
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-sus-1">{gameplan.booking}</Tooltip>
                                                                    }>
                                                                    <span>{gameplan.booking === "Complete" ? "Completed" : gameplan.booking}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Tooltip id="btn-view-1">View Booking Details</Tooltip>
                                                                    }>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/booking/${gameplan._id}`,
                                                                            state: {
                                                                                type: 'booking'
                                                                            }
                                                                        }}
                                                                    // to={`/booking/${gameplan._id}`}
                                                                    ><i className="fa fa-eye view-eye" aria-hidden="true" /></Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} handlePageChange={handlePageChange} currentPage={paginationObj.page} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Booking;