import { useState, useEffect, useContext } from 'react';
import { config } from "../../config/config";
import Loader from '../common/Loader';
import { userServices } from "../../services";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Table } from 'react-bootstrap';
import swal from 'sweetalert';
import { context } from '../../GlobalContext/GlobalContext';
import { format } from 'date-fns';
import DefaultUser from "../../assets/images/img.png";
import moment from 'moment';

const BookingDetails = () => {
    const globalData = useContext(context)
    const [gameplan, setGameplan] = useState();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [BookingId, setBookingId] = useState('')
    const history = useHistory();

    useEffect(() => {
        getGameplanDetals(id);
        setBookingId(id)
        globalData.setUserTabOpen(false)
        globalData.setMainTab('booking')
    }, []);

    function getGameplanDetals(gameplanId) {
        let params = {
            gameplan_id: gameplanId
        }
        setLoading(true);
        userServices.GetBookingDetails(params).then((res) => {
            if (res.data.data) {
                console.log("hello", res.data.data[0])

                setGameplan(res.data.data[0])
            } else {
                setGameplan({});
            }
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
        })
    }
    const onPaymentHold = (status) => {
        let params = {
            id: id,
            data: {
                payout_status: status
            }
        }

        setLoading(true);
        userServices.paymentHold(params).then((res) => {
            getGameplanDetals(id);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            if (err.response) {
                swal("Error", err.response.data.message)
            } else {
                swal("Error", "Server error Please refresh the page", "error")
            }
        })
    }

    function handleBackButton() {
        if (history.location.state) {
            if (history.location.state.type === "booking") {
                history.push(`/${history.location.state.type}`)
            }
            if (history.location.state.type === "holdPayment") {
                history.push(`/${history.location.state.type}`)
            }
            if (history.location.state.prevType === "native") {
                if (history.location.state.prevUrl) {
                    history.push(`${history.location.state.prevUrl}`)
                } else {
                    history.goBack()
                }
            }
            if (history.location.state.prevType === "booking") {
                history.push(`/${history.location.state.prevType}`)
            }
        } else {
            history.goBack()
        }
    }

    const showGamePlanCreator = history.location.state ? history.location.state.type === "native" ? true : false : false;

    return (
        <>
            {loading && <Loader />}
            {gameplan ?
                <>
                    <div className='sale_graph transaction_box mb-3'>
                        <Row className='justify-content-between align-items-center'>
                            <Col md={6} className="text-right">
                                <div className='bact-div'>
                                    <Button onClick={() => handleBackButton()} className='back-bt' variant="unset"> <i className="fa fa-angle-left" aria-hidden="true"></i>  Back</Button>
                                </div>
                            </Col>
                            {
                                showGamePlanCreator ? "" : <Col md={6} className="text-right">
                                    <button className="btn btn-primary" onClick={() => {
                                        history.push({
                                            pathname: `/userdetails/${gameplan.gameplan_owner}`,
                                            state: {
                                                type: `booking/${BookingId}`,
                                                prevType: `booking`
                                            }
                                        })
                                    }}>Gameplan Creator</button>
                                </Col>
                            }

                        </Row>
                        <Row className='justify-content-between align-items-center'>
                            <Col md={12}>
                                <h2 className='mb-2'>Booking Details</h2>
                            </Col>
                            <Col md={6} >
                                <span className='game-txt d-block'>Gameplan image</span>
                                {gameplan.gameplan.gameplan_image ?
                                    <img
                                        className="profile_picture gamplan-img"
                                        src={config.imageUrl + gameplan.gameplan.gameplan_image}
                                        width={100}
                                        height={100}
                                        alt="Profile Picture"
                                        onError={(event) => { event.target.src = DefaultUser }}
                                    />
                                    :
                                    <img
                                        className="profile_picture"
                                        src={require('../../assets/images/img.png')}
                                        width={100}
                                        height={100}
                                        alt="Profile Picture"
                                    />
                                }

                            </Col>

                        </Row>
                        <Row className='user-detail-main'>

                            <Col md={4} className="mb-3">
                                <span>Gameplan Title:<h6>{gameplan.gameplan.title}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Description:<h6>{gameplan.gameplan.description}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Location:<h6>{gameplan.gameplan.gameplan_location}</h6></span>
                            </Col>

                            <Col md={4} className="mb-3">
                                <span>Booking Date:<h6>
                                    {format(new Date(gameplan.createdAt), 'MM/dd/yyyy')}


                                </h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Event Date:<h6>
                                    {format(new Date(gameplan.booking_date), 'MM/dd/yyyy')}

                                </h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Number of attendees:<h6>{gameplan.number_of_attendes}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Price per person:<h6>$ {gameplan.gameplan_price}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Total Duration:<h6>{gameplan.gameplan.total_duration_for_location}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                {console.log(gameplan.bookingForSelf)}
                                <span>Booking for self:<h6>{gameplan.bookingForSelf ? 'True' : 'False'}</h6></span>
                            </Col>

                            {!moment(gameplan.booking_date).isBefore(gameplan.booking_date, "date") && gameplan.payout_status === "ONHOLD" || "PENDING" && !gameplan.payout_initiated && gameplan.booking_status === "PENDING" || gameplan.booking_status === "SUCCESS" ?
                                <Col md={5} className="mb-10">
                                    <label className='hold_payment' >Payment hold:</label>
                                    <div className="togglebtn">
                                        <label className="switch">
                                            <input type="checkbox" checked={gameplan.payout_status === "ONHOLD" ? true : false}
                                                onChange={() => onPaymentHold(gameplan.payout_status === "ONHOLD" ? "PENDING" : "ONHOLD")}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                </Col>
                                : null}
                        </Row>
                    </div>

                    {gameplan?.name_of_attendes?.length > 0 &&
                        <div className='sale_graph transaction_box mb-3'>
                            <span>Attendee:</span>
                            <Row md={8} className="mb-8">
                                <>
                                    <div className="table_box text-center">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Attendee name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gameplan.name_of_attendes.map((attendee, key) => {
                                                    console.log("dsdsdffdsf", key)
                                                    return (
                                                        <tr id={`${attendee.name}_${key}`}>
                                                            <td>
                                                                <span>{key + 1}</span>
                                                            </td>
                                                            <td>
                                                                <span>{attendee.name}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>

                                    </div>
                                </>
                            </Row>
                        </div>
                    }

                </>
                :
                <div className='sale_graph transaction_box mb-3'>
                    <div className='row'>
                        <div className='col-md-6 text-right'>
                            <div className='bact-div'>
                                <button type='button' onClick={() => handleBackButton()} className='back-bt btn btn-unset'><i className="fa fa-angle-left" aria-hidden="true" /> Back</button>
                            </div>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            No data available
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default BookingDetails;

{/* <div className='row mt-4'><div className='col-md-12 text-center'>No Record Found</div></div> */ }