import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
    return (
        <>
            <footer className="py-4">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="text-left">
                                <p className="footer_text text-center mb-0">© {new Date().getFullYear()} NATIVE NIGHT LIFE | ALL RIGHTS RESERVED.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}
export default Footer;