import { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import swal from 'sweetalert';
import Loader from '../common/Loader';
import { userServices } from "../../services";
import { config } from "../../config/config";
import { context } from '../../GlobalContext/GlobalContext';
import DefaultUser from "../../assets/images/img.png";

const TransactionDetails = () => {
    const globalData = useContext(context)
    const [gameplan, setGameplan] = useState();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [transactionId, setTransactionId] = useState('')

    useEffect(() => {
        getGameplanDetals(id);
        setTransactionId(id)
        globalData.setUserTabOpen(false)
        globalData.setMainTab('allTransactions')
    }, [])



    function getGameplanDetals(gameplanId) {
        let params = {
            gameplan_id: gameplanId
        }
        setLoading(true);
        userServices.GetTransactionsDetails(params).then((res) => {
            setGameplan(res.data.data)
            setLoading(false);
        }).catch(error => {
            swal("Error", "Server error Please refresh the page", "error")
        })
    }

    function handleBackButton() {
        history.push(`/allTransactions`);
    }

    return (
        <>
            {loading && <Loader />}
            {gameplan ?
                <>
                    <div className='sale_graph transaction_box mb-3'>
                        <div className='bact-div'>
                            <Button onClick={() => handleBackButton()} className='back-bt' variant="unset"> <i className="fa fa-angle-left" aria-hidden="true" /> Back</Button>
                        </div>
                        <Row>
                            <Col>
                                <h2 className='mb-2'>Booking Details</h2>
                            </Col>
                            <Col md={12}>
                                {gameplan.gameplan.gameplan_image ?
                                    <img
                                        className="profile_picture"
                                        src={config.imageUrl + gameplan.gameplan.gameplan_image}
                                        width={100}
                                        height={100}
                                        alt="Profile"
                                        onError={(event) => { event.target.src = DefaultUser }}
                                    />
                                    :
                                    <img
                                        className="profile_picture"
                                        src={require('../../assets/images/img.png')}
                                        width={100}
                                        height={100}
                                        alt="Profile"
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className='user-detail-main'>
                            <Col md={12} className="mb-3">
                                <button className="btn btn-primary" onClick={() => {
                                    history.push({
                                        pathname: `/userdetails/${gameplan.user._id}`,
                                        state: {
                                            type: `transactionDetails/${transactionId}`,
                                            prevType: `allTransactions`
                                        }
                                    })
                                }}>
                                    Gameplan booked by
                                </button>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Title:<h6>{gameplan.gameplan.gameplan.title}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Description:<h6>{gameplan.gameplan.gameplan.description}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Gameplan Location:<h6>{gameplan.gameplan.gameplan.gameplan_location}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Booking Date:<h6>
                                    {format(new Date(gameplan.gameplan.createdAt), 'MM/dd/yyyy')}
                                </h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Event Date:<h6>
                                    {format(new Date(gameplan.gameplan.booking_date), 'MM/dd/yyyy')}
                                </h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Number of attendees:<h6>{gameplan.gameplan.gameplan.number_of_attendes}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Price per person:<h6>{gameplan.gameplan.gameplan_price}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Total PRICE:<h6>${gameplan.gameplan.total_price}</h6></span>
                            </Col>
                            <Col md={4} className="mb-3">
                                <span>Payment status:<h6>{gameplan.gameplan.payment_status}</h6></span>
                            </Col>
                        </Row>
                    </div>

                    {gameplan?.name_of_attendes?.length > 0 &&
                        <div className='sale_graph transaction_box mb-3'>
                            <span>Attendee:</span>
                            <Row md={8} className="mb-8">
                                <>
                                    <div className="table_box text-center">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Attendee name</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gameplan.name_of_attendes.map((attendee, key) => {
                                                    return (
                                                        <tr id={key}>
                                                            <td>
                                                                <span>{key + 1}</span>
                                                            </td>
                                                            <td>
                                                                <span>{attendee.name}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            </Row>
                        </div>
                    }
                </>
                : null}
        </>
    )
}

export default TransactionDetails;