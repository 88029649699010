import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Table, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { format } from 'date-fns';
import swal from 'sweetalert';
import { Pagination } from '../../utils/pagination';
import { userServices } from "../../services";
import Loader from '../common/Loader';
import { context } from '../../GlobalContext/GlobalContext';
import { LIMIT_PER_PAGE } from '../../assets/constants/constants';

const HoldPaymentList = () => {
    const globalData = useContext(context);
    const history = useHistory();
    const [gameplans, setGameplans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchStart, setIsSearchStart] = useState(false)
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
    const [paginationObj, setPaginationObj] = useState({
        page: globalData.pageSelected || 0,
        totalpages: 0,
        totalRecord: 0
    });


    useEffect(() => {
        allGameplans()
    }, [])

    useEffect(() => {
        if (isPaginationTrigger) {
            allGameplans()
        }
    }, [isPaginationTrigger])

    function allGameplans() {
        let params = {
            page: paginationObj.page,
            size: LIMIT_PER_PAGE,
            search: searchKeyword,
            status: globalData.visibilityStatusFilter,
            payment_status: globalData.paymentStatusFilter
        }
        setLoading(true);
        userServices.GetAllHoldPayment(params).then((res) => {
            if (res.status === 200) {
                setGameplans(res.data.data)
                setPaginationObj(prevPaginationObj => {
                    return {
                        ...prevPaginationObj,
                        page: parseInt(paginationObj.page),
                        totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
                        totalRecord: res.data.totalCount
                    }
                });
                setIsPaginationTrigger(false);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.response?.status === 401) {
                localStorage.clear()
                history.push("/")
            } else {
                swal("Error", "Server error", "error")
            }
        })
    }

    // trigger on pagination onPageChange.
    const handlePageChange = (event) => {
        handlePagination('page', parseInt(event.selected));
        globalData.setPageSelected(parseInt(event.selected))
        setIsPaginationTrigger(true);
    };


    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setIsSearchStart(true)
        allGameplans()
    }

    const resetSearch = () => {
        setSearchKeyword("");
        handlePagination('page', 0);
        setIsPaginationTrigger(true);
        setTimeout(() => {
            setIsSearchStart(false);
        }, 1000);
    }

    // common function to handle pagination
    const handlePagination = (type, value) => {
        setPaginationObj(prevPaginationObj => {
            return {
                ...prevPaginationObj,
                [type]: value
            }
        });
    }

    return (
        <>
            {loading && <Loader />}
            <section className="botmanagement_section">
                <Container fluid>
                    <Row>
                        <Col md={12}>

                            <div className={`sale_graph transaction_box mb-3${gameplans && gameplans.length < 2 && " min-height-260"}`}>
                                <div className="trans_head mb-3">
                                    <div className="btn_title_row">
                                        <Row className='align-items-center mt-2'>
                                            <Col sm={4}>
                                                <h2 className="mb-2">Hold payment</h2>
                                            </Col>

                                            <Col sm={4} className="search-box">
                                                <form onSubmit={(event) => { handleSearchSubmit(event) }}>
                                                    <input type="text" placeholder='Search by Gameplan Title/Booked By' value={searchKeyword} className="form-control" onChange={(event) => setSearchKeyword(event.target.value)} required />
                                                    {
                                                        isSearchStart ? <button type='button' className='btn border-0' onClick={() => resetSearch()}><i className="fa fa-close" aria-hidden="true" /> </button> : <button type="submit" className='btn border-0'><i className="fa fa-search" aria-hidden="true" /></button>
                                                    }
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="table_box text-center">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Gameplan Title</th>
                                                <th>Booked By</th>
                                                <th>Booking Date</th>
                                                <th>Price</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gameplans.length ? gameplans.map((item, key) => {
                                                return (
                                                    <tr key={`${item?._id}_${key}`} id={key}>
                                                        <td className="table-data first">
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="btn-sus-1">{item?.gameplan?.title}</Tooltip>
                                                                }>
                                                                <span>{item?.gameplan?.title}</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td className="table-data first">
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="btn-sus-1">{item.user.first_name + " " + item.user.last_name}</Tooltip>
                                                                }>
                                                                <span>{item.user.first_name + " " + item.user.last_name}</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            {format(new Date(item?.booking_date), 'MM/dd/yyyy')}
                                                        </td>

                                                        <td className="table-data first">
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="btn-sus-1">${item?.total_price
                                                                    }</Tooltip>
                                                                }>
                                                                <span>${item?.total_price
                                                                }</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="btn-view-1">View Hold Details</Tooltip>
                                                                }>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/booking/${item?._id}`,
                                                                        state: {
                                                                            type: 'holdPayment'
                                                                        }
                                                                    }}><i className="fa fa-eye view-eye" aria-hidden="true"></i></Link>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                                :
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {paginationObj.totalpages > 1 ? <Pagination totalPages={paginationObj.totalpages} handlePageChange={handlePageChange} currentPage={paginationObj.page} /> : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default HoldPaymentList;