import { useEffect, useState } from "react";
import { context } from "./GlobalContext";

const Context = (props) => {

    const [token, setToken] = useState('');
    const [headerImage, setHeaderImage] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [fullName, setFullName] = useState('');

    const [userTabOpen, setUserTabOpen] = useState(false);
    const [nativeUsersTabOpen, setNativeUsersTabOpen] = useState(true);
    const [transactionsTabOpen, setTransactionsTabOpen] = useState(false);
    const [transactionsOpen, setTransactionsOpen] = useState(true)
    const [mainTab, setMainTab] = useState(localStorage.getItem('active_tab') || 'all_users');
    const [subTab, setSubTab] = useState(localStorage.getItem('active_sub_tab') || 'native_users');
    const [userTypeTab, setUserTypeTab] = useState('userDashboard');
    const [pageSelected, setPageSelected] = useState(0);
    const [visibilityStatusFilter, setVisibilityStatusFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
    const [isImageTrigger, setIsImageTrigger] = useState(false);


    useEffect(() => {
        if (localStorage.getItem('auth') !== '' && localStorage.getItem('auth') !== null) {
            setToken(localStorage.getItem('auth'));
            setUserId(localStorage.getItem('user_id'));
            setEmail(localStorage.getItem('email'));
            setFullName(localStorage.getItem('name'));
        }

        if (localStorage.getItem("picture")) {
            setHeaderImage(localStorage.getItem("picture"));
        } else {
            setHeaderImage('')
        }
    }, []);

    var data = {
        token, setToken,
        headerImage, setHeaderImage,
        email, setEmail,
        userId, setUserId,
        fullName, setFullName,
        mainTab, setMainTab,
        subTab, setSubTab,
        userTypeTab, setUserTypeTab,
        userTabOpen, setUserTabOpen,
        nativeUsersTabOpen, setNativeUsersTabOpen,
        pageSelected, setPageSelected,
        visibilityStatusFilter, setVisibilityStatusFilter,
        transactionsTabOpen, setTransactionsTabOpen,
        transactionsOpen, setTransactionsOpen,
        paymentStatusFilter, setPaymentStatusFilter,
        isImageTrigger, setIsImageTrigger
    };

    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;