import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import swal from "sweetalert";
import { format } from "date-fns";

import { Pagination } from "../../utils/pagination";
import { userServices } from "../../services";
import Loader from "../common/Loader";
import { USER_TYPE } from "../../assets/constants/constants";
import { context } from "../../GlobalContext/GlobalContext";
import { LIMIT_PER_PAGE } from "../../assets/constants/constants";
// CSS
import "../../assets/css/style.css";
import Swal from "sweetalert2";

const Dashboard = () => {
  const globalData = useContext(context);
  const history = useHistory();
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearchStart, setIsSearchStart] = useState(false);
  const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
  const [paginationObj, setPaginationObj] = useState({
    page: globalData.pageSelected || 0,
    totalpages: 0,
    totalRecord: 0,
  });

  useEffect(() => {
    allUsersList();
    globalData.setMainTab("all_users");
    globalData.setUserTypeTab("");
  }, []);

  useEffect(() => {
    if (isPaginationTrigger) {
      allUsersList();
    }
  }, [isPaginationTrigger]);

  function allUsersList() {
    let params = {
      type: USER_TYPE.NEWBIE,
      pageNo: paginationObj.page,
      size: LIMIT_PER_PAGE,
      search: searchKeyword,
    };
    setLoading(true);
    userServices
      .GetUsersListForAdmin(params)
      .then((res) => {
        if (res.status === 200) {
          console.log("users list res===>>", res.data);
          setUsersList(res.data.data);
          setPaginationObj((prevPaginationObj) => {
            return {
              ...prevPaginationObj,
              page: parseInt(paginationObj.page),
              totalpages: Math.ceil(res.data.usersListCount / LIMIT_PER_PAGE),
              totalRecord: res.data.usersListCount,
            };
          });
          setIsPaginationTrigger(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          history.push("/");
        } else {
          swal("Error", "Server error", "error");
        }
      });
  }
  const deleteBtn = (id) => {
    console.log("id", id);
    userServices.deleteAccount(id).then((res) => {
      console.log("deleted User", res);
      Swal.fire({
        title: "Deleted Account!",
        text: "Account will be deleted",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          history.push("/");
        }
      });
    });
  };
  const deleteAction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBtn(id);
      }
    });
  };
  // trigger on pagination onPageChange.
  const handlePageChange = (event) => {
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        page: parseInt(event.selected),
      };
    });
    globalData.setPageSelected(parseInt(event.selected));
    setIsPaginationTrigger(true);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setIsSearchStart(true);
    allUsersList();
  };

  const resetSearch = () => {
    setSearchKeyword("");
    // setIsSearchStart(false)
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        page: 0,
      };
    });
    setIsPaginationTrigger(true);
    setTimeout(() => {
      setIsSearchStart(false);
    }, 1000);
  };

  return (
    <>
      {loading && <Loader />}
      <section className="botmanagement_section">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="sale_graph transaction_box mb-3">
                <div className="trans_head mb-3">
                  <div className="btn_title_row">
                    <Row className="align-items-center">
                      <Col sm={6}>
                        <h2 className="mb-2">Newbie Users</h2>
                      </Col>
                      <Col sm={4} className="search-box">
                        <form
                          onSubmit={(event) => {
                            handleSearchSubmit(event);
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search by Name/Email/Phone No."
                            value={searchKeyword}
                            className="form-control"
                            onChange={(event) =>
                              setSearchKeyword(event.target.value)
                            }
                            required
                          />
                          {isSearchStart ? (
                            <button
                              type="button"
                              className="btn border-0"
                              onClick={() => resetSearch()}
                            >
                              <i className="fa fa-close" aria-hidden="true" />{" "}
                            </button>
                          ) : (
                            <button type="submit" className="btn border-0">
                              <i className="fa fa-search" aria-hidden="true" />
                            </button>
                          )}
                        </form>
                        {/* <Form.Control placeholder='Search by Name/Email/Phone No.' value={searchKeyword}
                                                    onChange={e => {
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                                {isSearchStart ?<i className="fa fa-close" aria-hidden="true" onClick={() => {
                                                        setSearchKeyword("")
                                                        setIsSearchStart(false)
                                                        setPaginationObj(prevPaginationObj => {
                                                            return {
                                                                ...prevPaginationObj,
                                                                page: 0,
                                                            }
                                                        });
                                                        setIsPaginationTrigger(true);
                                                    }} />: <i className="fa fa-search" aria-hidden="true" onClick={() => {
                                                        if (searchKeyword) {
                                                            setIsSearchStart(true)
                                                            allUsersList()
                                                        }
                                                    }} />
                                                } */}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="table_box text-center">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email/Phone No.</th>
                        <th>Created On</th>
                        <th>Last Activity On</th>
                        <th>Gender</th>
                        <th>Neighbourhood Area</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersList.length ? (
                        usersList.map((user, key) => {
                          return (
                            <tr key={`${user._id}_${key}`} id={key}>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.first_name + " " + user.last_name}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {user.first_name + " " + user.last_name}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.email
                                        ? user.email
                                        : user.phone_number}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {user.email
                                      ? user.email
                                      : user.phone_number}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                {format(new Date(user.createdAt), "MM/dd/yyyy")}
                              </td>
                              <td>
                                {format(new Date(user.updatedAt), "MM/dd/yyyy")}
                              </td>
                              <td>{user.gender}</td>
                              <td className="table-data first">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-sus-1">
                                      {user.user_neighbourhood_area}
                                    </Tooltip>
                                  }
                                >
                                  <span>{user.user_neighbourhood_area}</span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      View User Details
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/userdetails/${user._id}`}>
                                    <i
                                      className="fa fa-eye view-eye"
                                      aria-hidden="true"
                                    />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="btn-view-1">
                                      Delete Account
                                    </Tooltip>
                                  }
                                >
                                  <Link>
                                    <i
                                      onClick={() => deleteAction(user?._id)}
                                      className="fa fa-trash delete-icon"
                                      aria-hidden="true"
                                    />
                                  </Link>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={12}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {paginationObj.totalpages > 1 ? (
                  <Pagination
                    totalPages={paginationObj.totalpages}
                    handlePageChange={handlePageChange}
                    currentPage={paginationObj.page}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Dashboard;
