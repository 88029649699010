import { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import swal from "sweetalert";
import { context } from "../../GlobalContext/GlobalContext";
import { config } from "../../config/config";
import { userServices } from "../../services";
import Loader from "../common/Loader";
import { LIMIT_PER_PAGE } from "../../assets/constants/constants";
import { USER_TYPE } from "../../assets/constants/constants";
// UTILS
import { USER_BOOKING_FILTER, PAGINATION_OBJECT } from "../../utils/constant";
import { Pagination } from "../../utils/pagination";
// IMAGE
import DefaultUser from "../../assets/images/img.png";
import Swal from "sweetalert2";

const UserDetails = () => {
  const globalData = useContext(context);

  const [user, setUser] = useState();
  const { id } = useParams();
  const [userGameplans, setUserGameplans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);
  const [paginationObj, setPaginationObj] = useState({
    page: globalData.pageSelected || 0,
    totalpages: 0,
    totalRecord: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();

  const [allBooking, setAllBooking] = useState([]);

  // User Booking Service
  const [bookingSearch, setBookingSearch] = useState("");
  const [bookingfilter, setBookingFilter] = useState("");
  const [isFilterTriggered, setIsFilterTriggered] = useState(false);
  const [pagination, setPagination] = useState({
    // ...PAGINATION_OBJECT
    page: globalData.pageSelected || 0,
    totalpages: 0,
  });
  const [isSearchStart, setIsSearchStart] = useState(false);
  const [gamePlanId, setGamePlanId] = useState("");
  const [gamePlanBooking, setGamePlanBooking] = useState([]);

  let location = useLocation();

  useEffect(() => {
    getUserDetails(id);
    // getAllBooking(id);

    if (location.state) {
      if (location.state.showGamePlanBooking) {
        getUserGameplanList(location.state.gamePlanId);
        setGamePlanId(location.state.gamePlanId);
        if (location.state.prevType === "suspendedGameplan") {
          globalData.setMainTab("suspendedGameplan");
        }
      }
    } else {
    }
  }, []);

  useEffect(() => {
    if (isPaginationTrigger || searchKeyword) {
      getUserGameplans(id);
    }
  }, [searchKeyword, isPaginationTrigger]);

  const deleteBtn = () => {
    console.log("id", id);
    userServices.deleteAccount(id).then((res) => {
      console.log("deleted User", res);
      Swal.fire({
        title: "Deleted Account!",
        text: "Account will be deleted",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          history.push("/");
        }
      });
    });
  };
  const deleteAction = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBtn();
      }
    });
  };
  // get user detail by user id
  function getUserDetails(user_id) {
    let params = {
      user_id: user_id,
    };
    setLoading(true);
    userServices
      .GetUserDetails(params)
      .then((res) => {
        setLoading(false);
        setUser(res.data.data);
        globalData.setMainTab("all_users");
        globalData.setUserTabOpen(true);
        setLoading(false);
        if (res.data.data.user_type === "NA") {
          globalData.setNativeUsersTabOpen(true);
          globalData.setSubTab("native_users");
          if (res.data.data.user_profile_status === "APPR") {
            globalData.setUserTypeTab("approved");
          } else if (res.data.data.user_profile_status === "PEND") {
            globalData.setUserTypeTab("unapproved");
          } else {
            globalData.setUserTypeTab("");
          }
          userBooking(id);
          getUserGameplans(id);
        } else if (res.data.data.user_type === "NW") {
          // calling API of all booking list of active user
          // in new bie case admin can see all booking of user
          userBooking(id);
          globalData.setNativeUsersTabOpen(false);
          globalData.setSubTab("newbie_users");
        } else {
          globalData.setSubTab("incomplete_profile_users");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoading(false);
          localStorage.clear();
          history.push("/");
        } else {
          setLoading(false);
          swal("Error", "Server error", "error");
        }
      });
  }

  // get user game plan
  function getUserGameplans(user_id) {
    let params = {
      user_id: user_id,
      pageNo: paginationObj.page,
      size: LIMIT_PER_PAGE,
      search: searchKeyword,
    };
    userServices.GetUserGameplans(params).then((res) => {
      setUserGameplans(res.data.data || []);
      setPaginationObj((prevPaginationObj) => {
        return {
          ...prevPaginationObj,
          page: parseInt(paginationObj.page),
          totalpages: Math.ceil(res.data.totalCount / LIMIT_PER_PAGE),
          totalRecord: res.data.totalCount,
        };
      });
      setCurrentPage(parseInt(paginationObj.page));
      setIsPaginationTrigger(false);
    });
  }

  // get gameplan user list
  function getUserGameplanList(id) {
    userServices
      .getUserGameplanList(id)
      .then((response) => {
        if (response.data.data) {
          setGamePlanBooking(response.data.data);
        }
      })
      .catch((error) => {});
  }

  // API for enable disable user
  const enableDisableUser = (params) => {
    setLoading(true);
    userServices
      .EnableDisableUser(params)
      .then((res) => {
        setLoading(false);
        getUserDetails(id);
      })
      .catch((error) => {
        swal("Error", "Server error Please refresh the page", "error");
      });
  };

  // API for approve and disapprove user
  const approveDisapproveUser = (params) => {
    setLoading(true);
    userServices
      .ApproveDisapproveUser(params)
      .then((res) => {
        setLoading(false);
        swal(
          params.userProfileStatus === "APPR" ? "Success" : "Error",
          `User profile ${
            params.userProfileStatus === "APPR" ? "approved" : "unapproved"
          } successfully`,
          params.userProfileStatus === "APPR" ? "success" : "Error"
        );
        getUserDetails(id);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          history.push("/");
        } else {
          swal("Error", "Server error", "error");
        }
      });
  };

  // trigger on pagination onPageChange.
  const handlePageChange = (event) => {
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        page: parseInt(event.selected),
      };
    });
    globalData.setPageSelected(parseInt(event.selected));
    setIsPaginationTrigger(true);
  };

  // function getAllBooking(user_id) {
  //     let params = {
  //         user_id: user_id,
  //         page: paginationObj.page,
  //         size: LIMIT_PER_PAGE,
  //         search: searchKeyword
  //     };
  //     userServices.GetAllBooking(params).then((response) => {
  //         console.log('response...', response);

  //     }).catch((error) => {
  //         console.log('error...', error);
  //         setAllBooking([]);
  //     })
  // }

  function userBooking(user_id) {
    userServices
      .userBooking(
        `${user_id}?page=${pagination.page}&size=${LIMIT_PER_PAGE}${
          bookingSearch ? `&search=${bookingSearch}` : ``
        }${bookingfilter ? `&status=${bookingfilter}` : ``}`
      )
      .then((response) => {
        if (response.data.data) {
          setAllBooking(response.data.data);
          handleCommonPagination("totalpages", response.data.totalPages);
        } else {
          setAllBooking([]);
          handleCommonPagination("totalpages", 0);
        }
        setIsFilterTriggered(false);
        setLoading(false);
      })
      .catch((error) => {
        setIsFilterTriggered(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    console.log(id);
    if (isFilterTriggered) {
      userBooking(id);
      setLoading(true);
    }
  }, [isFilterTriggered]);

  function handleUserBookingPagination(event) {
    handleCommonPagination("page", parseInt(event.selected));
    globalData.setPageSelected(parseInt(event.selected));
    setIsFilterTriggered(true);
  }

  function handleCommonPagination(type, value) {
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        [type]: parseInt(value),
      };
    });
  }

  // handling search submit of userdetail page
  function handleSearchSubmit(event) {
    event.preventDefault();
    handleCommonPagination("page", 0);
    setIsFilterTriggered(true);
    setIsSearchStart(true);
  }

  // reset data of search field in user detail page
  function resetSearch() {
    setBookingSearch("");
    // setIsSearchStart(false);
    setIsFilterTriggered(true);
    setTimeout(() => {
      setIsSearchStart(false);
    }, 1000);
  }

  function handleBackButton() {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: `/${location.state.type}/${location.state.id}`,
          state: {
            type: location.state.type,
          },
        });
      }
      if (location.state.type) {
        // prevtype is for handling initial page route
        // i.e first page is gameplan, second page is gameplan detail and third is userdetail
        // handle route for firstpage prevType variable hold gameplan route
        if (location.state.prevType) {
          history.push({
            pathname: `/${location.state.type}`,
            state: {
              prevType: location.state.prevType,
            },
          });
        } else {
          history.push(`/${location.state.type}`);
        }
      }
    } else {
      history.push(
        globalData.userTypeTab === "unapproved" ? "/unapproved" : "/approved"
      );
    }
  }

  return (
    <>
      {loading && <Loader />}
      <>
        <div className="sale_graph transaction_box mb-3">
          <div className="bact-div">
            <Button
              onClick={() => handleBackButton()}
              className="back-bt"
              variant="unset"
            >
              {" "}
              <i className="fa fa-angle-left" aria-hidden="true" /> Back
            </Button>
            {user ? (
              <div>
                {/* globalData.userTypeTab == "unapproved" */}
                {globalData.userTypeTab == "unapproved" ? (
                  ""
                ) : (
                  <span>
                    Profile Action:
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="btn-del-1">
                          {user.status === "ACT"
                            ? "Disable User"
                            : "Enable User"}
                        </Tooltip>
                      }
                    >
                      <div className="togglebtn">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={user.status === "ACT" ? true : false}
                            onChange={(e) => {
                              let updateStatus =
                                user.status === "ACT" ? "DIS" : "ACT";
                              enableDisableUser({
                                user_id: user._id,
                                status: updateStatus,
                              });
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </OverlayTrigger>
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {user ? (
            <>
              <Row>
                <Col>
                  <h2 className="mb-2">User Details</h2>
                </Col>

                <Col md={12}>
                  <img
                    className="profile_picture"
                    src={
                      user.user_image
                        ? config.imageUrl + user.user_image
                        : require("../../assets/images/img.png")
                    }
                    width={100}
                    height={100}
                    alt="User Profile"
                    onError={(event) => {
                      event.target.src = DefaultUser;
                    }}
                  />
                </Col>
              </Row>
              <Row className="user-detail-main">
                <Col md={4} className="mb-3">
                  <span>
                    Full Name:
                    <h6>
                      {user.first_name} {user.last_name}
                    </h6>
                  </span>
                </Col>

                <Col md={4} className="mb-3">
                  <span>
                    DOB:
                    <h6>
                      {user.dob
                        ? format(new Date(user.dob), "MM/dd/yyyy")
                        : "-"}
                    </h6>
                  </span>
                </Col>
                {user.user_type == USER_TYPE.NATIVE ||
                user.user_type == USER_TYPE.NEWBIE ? (
                  <Col md={4} className="mb-3">
                    <span>
                      Profile Action:
                      {user.user_profile_status === "APPR" ? (
                        <h6>Approved</h6>
                      ) : (
                        <div className="togglebtn">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="btn-del-1">
                                Allow to approve this profile
                              </Tooltip>
                            }
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                disabled={
                                  user.user_profile_status === "APPR"
                                    ? true
                                    : false
                                }
                                checked={
                                  user.user_profile_status === "APPR"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let updateProfileStatus;
                                  if (user.user_profile_status === "APPR") {
                                    updateProfileStatus = "PEND";
                                  } else if (
                                    user.user_profile_status === "PEND"
                                  ) {
                                    updateProfileStatus = "APPR";
                                  } else {
                                    updateProfileStatus = "";
                                  }
                                  approveDisapproveUser({
                                    user_id: user._id,
                                    userProfileStatus: updateProfileStatus,
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </OverlayTrigger>
                        </div>
                      )}
                    </span>
                  </Col>
                ) : null}
                <Col md={4} className="mb-3">
                  <span>
                    Email/Phone No:
                    <h6>{user.email ? user.email : user.phone_number}</h6>
                  </span>
                </Col>
                <Col md={4} className="mb-3">
                  <span>
                    Gender:<h6>{user.gender ? user.gender : "-"}</h6>
                  </span>
                </Col>
                {user.preffered_pronounce ? (
                  <Col md={4} className="mb-3">
                    <span>
                      Prefered pronouns:<h6>{user.preffered_pronounce}</h6>
                    </span>
                  </Col>
                ) : null}
                {/* <Col md={4} className="mb-3">
                                <span>Role:<h6>{user.role ? user.role : "-"}</h6></span>
                            </Col> */}
                <Col md={4} className="mb-3">
                  <span>
                    Type:
                    <h6>
                      {!user.user_type
                        ? "-"
                        : user.user_type === USER_TYPE.NATIVE
                        ? "Native"
                        : user.user_type === USER_TYPE.NEWBIE
                        ? "Newbie"
                        : "-"}
                    </h6>
                  </span>
                </Col>
                <Col md={4} className="mb-3">
                  {/* new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(user.createdAt ? user.createdAt : "-")) */}
                  <span>
                    Created At:
                    <h6>
                      {user.createdAt
                        ? format(new Date(user.createdAt), "MM/dd/yyyy")
                        : "-"}
                    </h6>
                  </span>
                </Col>
                {/* <Col md={4} className="mb-3">
                                <span>Updated At:<h6>{new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(user.updatedAt ? user.updatedAt : "-"))}</h6></span>
                            </Col> */}
                <Col md={4} className="mb-3">
                  <span>
                    User Music Choice:
                    <h6>
                      {user.user_music_choice.length
                        ? user.user_music_choice.map((e) => {
                            return (
                              <span className="perk-style" key={`${e._id}`}>
                                {e?.sub_category_name}
                              </span>
                            );
                          })
                        : "-"}
                    </h6>
                  </span>
                </Col>
                <Col md={4} className="mb-3">
                  <span>
                    User Neighbourhood Area:
                    <h6>
                      {user.user_neighbourhood_area
                        ? user.user_neighbourhood_area
                        : "-"}
                    </h6>
                  </span>
                </Col>
                <Col md={4} className="mb-3">
                  <span>
                    User Spot Choice:
                    <h6>
                      {user.user_spot_choice.length
                        ? user.user_spot_choice.map((e) => {
                            return (
                              <span className="perk-style" key={`${e._id}`}>
                                {e?.sub_category_name}
                              </span>
                            );
                          })
                        : "-"}
                    </h6>
                  </span>
                </Col>
                <Col md={4} className="mb-3">
                  <span>
                    User Vibes Choice:
                    <h6>
                      {user.user_vibes_choice.length
                        ? user.user_vibes_choice.map((e) => {
                            return (
                              <span className="perk-style" key={`${e._id}`}>
                                {e?.sub_category_name}
                              </span>
                            );
                          })
                        : "-"}
                    </h6>
                  </span>
                </Col>
                {/* {user.stripe_customer_id
                                ?
                                <Col md={4} className="mb-3">
                                    <span>Stripe Id:<h6>{user.stripe_customer_id}</h6></span>
                                </Col>
                                : null} */}
                {user.preffered_languages ? (
                  <Col md={4} className="mb-3">
                    <span>
                      Prefered languages:<h6>{user.preffered_languages}</h6>
                    </span>
                  </Col>
                ) : null}
                {user.signature_drink ? (
                  <Col md={4} className="mb-3">
                    <span>
                      Signature Drink:<h6>{user.signature_drink}</h6>
                    </span>
                  </Col>
                ) : null}
                {user.how_i_roll ? (
                  <Col md={4} className="mb-3">
                    <span>
                      How I Roll:<h6>{user.how_i_roll}</h6>
                    </span>
                  </Col>
                ) : null}
              </Row>
              <div className="row">
                <div className="col-md-6 text-right">
                  {/* Instagram link */}

                  {user.instagram_profile_link ? (
                    <Link
                      to={{ pathname: `${user.instagram_profile_link}` }}
                      target="_blank"
                    >
                      <img
                        className="social_icons"
                        src={
                          require("../../assets/images/instagram.svg").default
                        }
                        width={28}
                        height={28}
                        alt="Social Icons"
                      />
                    </Link>
                  ) : null}
                  {/* Facebook link */}
                  {user.facebook_profile_link ? (
                    <Link
                      to={{ pathname: `${user.facebook_profile_link}` }}
                      target="_blank"
                    >
                      <img
                        className="social_icons"
                        src={
                          require("../../assets/images/facebook.svg").default
                        }
                        width={28}
                        height={28}
                        alt="Social Icons"
                      />
                    </Link>
                  ) : null}
                  {/* Tiktok link */}
                  {user.tik_tok_profile_link ? (
                    <Link
                      to={{ pathname: `${user.tik_tok_profile_link}` }}
                      target="_blank"
                    >
                      <img
                        className="social_icons"
                        src={require("../../assets/images/tik_tok.svg").default}
                        width={28}
                        height={28}
                        alt="Social Icons"
                      />
                    </Link>
                  ) : null}

                  {/* LinkedIn link */}
                  {user.linked_in_profile_link ? (
                    <Link
                      to={{ pathname: `${user.linked_in_profile_link}` }}
                      target="_blank"
                    >
                      <img
                        className="social_icons"
                        src={
                          require("../../assets/images/linkedin.svg").default
                        }
                        width={28}
                        height={28}
                        alt="Social Icons"
                      />
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 text-right">
                  <button
                    className="btn btn-inner"
                    onClick={() => {
                      deleteAction();
                    }}
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className='social_connections'></div> */}
        </div>

        <div className="sale_graph transaction_box mb-3">
          <Row>
            <Col>
              <span>User Gameplans:</span>
            </Col>

            <Col sm={3} className="search-box">
              <form
                onSubmit={(event) => {
                  getUserGameplans(id);
                }}
              >
                <input
                  type="text"
                  placeholder="Search by Gameplan Title/Booked By"
                  value={searchKeyword}
                  className="form-control"
                  onChange={(event) => setSearchKeyword(event.target.value)}
                  required
                />
              </form>
            </Col>
          </Row>
          {userGameplans?.length > 0 ? (
            <Row md={8} className="mb-8">
              <>
                <div className="table_box text-center">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Gameplan Title</th>
                        <th>Created on</th>
                        <th>Start Date</th>
                        <th>Location</th>
                        <th>Duration</th>
                        <th>Repetition</th>
                        <th>No of Attendees</th>
                        <th>Price per Attendee</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userGameplans.map((gameplan, key) => {
                        console.log("gameplan", gameplan);
                        return (
                          <tr id={key} key={`${gameplan._id}_${key}`}>
                            <td className="table-data first">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="btn-view-1">
                                    {gameplan.title}
                                  </Tooltip>
                                }
                              >
                                <span>{gameplan.title}</span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              {format(
                                new Date(gameplan.createdAt),
                                "MM/dd/yyyy"
                              )}
                            </td>
                            <td>
                              {format(
                                new Date(gameplan.start_date),
                                "MM/dd/yyyy"
                              )}
                            </td>
                            <td className="table-data first">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="btn-view-1">
                                    {gameplan.gameplan_location}
                                  </Tooltip>
                                }
                              >
                                <span>{gameplan.gameplan_location}</span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              <span>
                                {gameplan.total_duration_for_location}
                              </span>
                            </td>
                            <td>
                              <span>{gameplan.repeat_gameplan_schedule}</span>
                            </td>
                            <td>
                              <span>{gameplan.number_of_attendes}</span>
                            </td>
                            <td>
                              <span>
                                ${" "}
                                {parseFloat(
                                  gameplan.total_price /
                                    gameplan.number_of_attendes
                                ).toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="btn-view-1">
                                    View Gameplan Details
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={{
                                    pathname: `/booking/${gameplan._id}`,
                                    state: {
                                      prevType: "native",
                                      prevUrl: window.location.pathname,
                                    },
                                  }}
                                >
                                  <i
                                    className="fa fa-eye view-eye"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </>
            </Row>
          ) : (
            <div className="row">
              <div className="col-md-12 text-center">No data available</div>
            </div>
          )}
          {paginationObj.totalpages > 1 ? (
            <Pagination
              totalPages={paginationObj.totalpages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          ) : null}
        </div>

        {/* BOOKING SECTION */}
        {user ? (
          user.user_type === "NW" ? (
            <div className="sale_graph transaction_box mb-3">
              <div className="btn_title_row">
                <Row className="align-items-center mb-4">
                  <Col sm={6}>
                    <h2 className="mb-2">Booking</h2>
                  </Col>
                  <Col sm={2} className="filters-style">
                    Filter by status:
                    <Dropdown
                      onSelect={(e) => {
                        setBookingFilter(e);
                        handleCommonPagination("page", 0);
                        setIsFilterTriggered(true);
                      }}
                    >
                      <Dropdown.Toggle>
                        {USER_BOOKING_FILTER.map((value, index) => {
                          return (
                            <Fragment key={`${index}_${value.value}`}>
                              {bookingfilter === value.value ? value.title : ""}
                            </Fragment>
                          );
                        })}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {USER_BOOKING_FILTER.map((value, index) => {
                          return (
                            <Dropdown.Item
                              eventKey={value.value}
                              key={`${index}_${value.value}`}
                              className={
                                globalData.visibilityStatusFilter ===
                                value.value
                                  ? "theme-background-color"
                                  : ""
                              }
                            >
                              {value.title}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col sm={4} className="search-box">
                    <form
                      onSubmit={(event) => {
                        handleSearchSubmit(event);
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search by title"
                        value={bookingSearch}
                        className="form-control"
                        onChange={(event) =>
                          setBookingSearch(event.target.value)
                        }
                        required
                      />
                      {isSearchStart ? (
                        <button
                          type="button"
                          className="btn border-0"
                          onClick={() => resetSearch()}
                        >
                          <i className="fa fa-close" aria-hidden="true" />{" "}
                        </button>
                      ) : (
                        <button type="submit" className="btn border-0">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      )}
                    </form>
                  </Col>
                </Row>
              </div>
              <Row md={8}>
                <>
                  <div className="table_box text-center mb-4">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Gameplan Title</th>
                          <th>Description</th>
                          <th>Total Price</th>
                          <th>Total Time</th>
                          <th>Booking Date</th>
                          <th>Event Date</th>
                          <th>Booking Status</th>
                          <th>Booking for Self</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allBooking.length ? (
                          allBooking.map((booking, key) => {
                            console.log("booking...", booking);
                            return (
                              <tr id={key} key={`${booking._id}_${key}`}>
                                <td>{booking.gameplan.title}</td>
                                <td className="table-data first">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        {booking.gameplan.description}
                                      </Tooltip>
                                    }
                                  >
                                    <span>{booking.gameplan.description}</span>
                                  </OverlayTrigger>
                                </td>
                                <td className="table-data first">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        ${booking.total_price}
                                      </Tooltip>
                                    }
                                  >
                                    <span>{booking.total_price}</span>
                                  </OverlayTrigger>
                                </td>
                                <td className="table-data first">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        Not Applicable
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {
                                        booking?.gameplan
                                          ?.total_duration_for_location
                                      }
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  {format(
                                    new Date(booking.booking_date),
                                    "MM/dd/yyyy"
                                  )}
                                  {/* {new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(booking.booking_date))} */}
                                </td>
                                <td>
                                  {format(
                                    new Date(booking.createdAt),
                                    "MM/dd/yyyy"
                                  )}
                                  {/* {new Intl.DateTimeFormat('en-GB', DATE_OPTIONS).format(new Date(booking.createdAt))} */}
                                </td>
                                <td className="table-data first">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip
                                        id="btn-view-1"
                                        className="text-capitalize"
                                      >
                                        {booking.booking_status.toLowerCase()}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="text-capitalize">
                                      {booking.booking_status.toLowerCase()}
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td className="table-data first">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        {booking.bookingForSelf
                                          ? "True"
                                          : "False"}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {booking.bookingForSelf
                                        ? "True"
                                        : "False"}
                                    </span>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="btn-view-1">
                                        View booking Details
                                      </Tooltip>
                                    }
                                  >
                                    <Link to={`/booking/${booking._id}`}>
                                      <i
                                        className="fa fa-eye view-eye"
                                        aria-hidden="true"
                                      />
                                    </Link>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {pagination.totalpages > 1 ? (
                      <Pagination
                        totalPages={pagination.totalpages}
                        currentPage={pagination.page}
                        handlePageChange={handleUserBookingPagination}
                      />
                    ) : null}
                  </div>
                </>
              </Row>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default UserDetails;
