// const baseUrl = "http://192.168.2.122:5001"; // local
// const baseUrl = "http://192.168.2.95:5001"; // local
// const baseUrl = "http://localhost:3000";

// live
// const baseUrl = "http://198.211.110.165:5001";

export const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_BASE_URL + "/api/v1",
  imageUrl: process.env.REACT_APP_BASE_URL + '',
}
